import React from "react"

const Reports: React.FC = () => {
 
    return (
        <div style={{display:'flex', flex:1}}>
            <iframe
                title="Report"
                width="100%"
                height="AutoFit"
                src="https://blue-smoke-07193cc10.2.azurestaticapps.net/demo/report">
            </iframe>
        </div>
    )
  }

  export default Reports

