import React, { useEffect, useState } from 'react'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'
import AlignFormikTextInput from './AlignFormikTextInput'
import styled from 'styled-components'
import { useStore as useRootStore } from '../models/Root'
import { useUserManagerStore } from '../models/UserManager'
import { useNavigate } from 'react-router-dom'
import AlignButton from './AlignButton'
import { observer } from 'mobx-react-lite'
import useAxios from '../hooks/useAxios'
import { API_PATHS } from '../api/ApiPaths'
import alignHero from '../images/Align-G.png'
import Navbar from './Navbar'

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  div.overflow-handle {
    display: flex;
    flex: 1;
    overflow: auto;
  }
`

const LoginPanel = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  height: 500px;
  align-self: center;
  margin: 1rem;
  .hero-image {
    display: flex;
    height: 100%;
    flex: 1;
    padding-left: 2rem;
    padding-right: 2rem;
    background: center / contain no-repeat url(${alignHero});
    align-items: center;
    justify-content: center;
    background-size: 90%;
  }
  .login-form {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-right: 2rem;
    height: 100%;
    justify-content: center;
    border: 1px solid lightgray;
    border-radius: 1rem;
    .form-wrap {
      .user-credentials-error {
        display: flex;
        .alert-content{
          div {
            line-height: inherit;
          }
        }
      }
    }
  }  

`

const SetDevLoginCredentials:React.FC = ()=>{
  const {setValues} = useFormikContext()

  return (
    <div style={{paddingTop:'1rem'}}>
      <div>For demo/dev only, get credentials to login as:</div>
      <div style={{display:'flex'}}>
        <AlignButton 
        label="Demo user"
        onClick={()=>{setValues({email:'demouser@domain.com', password:'!4PasswordShare'})}}
          style={{marginRight: '1rem'}}
          type='button' />
      </div>
    </div>
  )

}

/**
 * @description Entry point component of the main application which uses a form requesting credentials for access, there will be SSO in the future.
 * for development purposes we have a SetDevLoginCredentials component to help with the tedious entering of credentials many times. 
 */

const Login:React.FC = observer(() => {
  const {setAuthToken, setAppUser} = useRootStore()
  const {login: fakeLogin} = useUserManagerStore()
  const navigate = useNavigate()
  const {data, error, payload, requestUrl, fetch} = useAxios({method:'POST',initialValue:null})
  const [loginError, setLoginError] = useState('')

  const login = (email:string, password:string)=>{
    //we need to call the API here, get the token if successful and return errors if not.
    payload.current = {
      email: email,
      password: password
    }
    requestUrl.current = `${API_PATHS.LOGIN}`
    fetch()
  }

  useEffect(()=>{
    if(data){
      if(data.issuccess){
        // we will redirect and do extra stuff here.
        //console.log('success login!', data)
        setAuthToken(data.entity.authToken)
        setAppUser(data.entity.appUser)
        //our fake users have roles, but API still does not, so we need to fake login them too for now
        fakeLogin('demouser@domain.com','!4PasswordShare')
        navigate("/reports",{replace:true})
      }else{
        setLoginError(data.message)
      }
    }
    if(error){
      setLoginError(error.message)
    }
  },[data, error, fakeLogin, navigate, setAuthToken, setAppUser])

  return (
    <AppWrapper>
      <Navbar
        style={{position:'relative', width:'100%', flexShrink:0}}
      />
      <div className='overflow-handle'>
        <LoginPanel className='login-wrap'>
          <div className='hero-image'>
          </div>
          <div className='login-form'>
            <div className='form-wrap'>
              <h2>Log in</h2>

              {loginError !== '' ? (
                  <div className="inline-error user-credentials-error">
                      <div className='material-icons icon-left icon-list-item error'>error</div>
                      <div className='alert-content'>
                          <div className='body-text s'>{loginError}</div>
                      </div>
                  </div>
                ) : null
              }

              <Formik
                initialValues={{
                  email: "",
                  password: ""
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email("Invalid email address")
                    .required("Required"),
                  password: Yup.string()
                    .required("Required")
                })}
                onSubmit={async (values, { setSubmitting }) => {
                  setAuthToken('')
                  login(values.email, values.password)
                  setSubmitting(false);
                }}
              >
                <Form>
                  <AlignFormikTextInput
                    label="Email"
                    name="email"
                    type="email"
                    placeholder="example@example.com"
                    materialIconName="mail_outline"
                    iconAlignment="left"
                  />
                  <AlignFormikTextInput
                    label="Password"
                    name="password"
                    type="password"
                    placeholder="Password"
                  />
                  <AlignButton 
                    label='Log in'
                    type='submit'
                    style={{width:'100%', textAlign:'center'}}
                  />
                  <SetDevLoginCredentials />
                </Form>
              </Formik>
            </div>
          </div>
        </LoginPanel>
      </div>
    </AppWrapper>
  )
})

export default Login
