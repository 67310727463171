import React from 'react'
import styled from 'styled-components'
import { useStore } from '../models/Root'

const DashboardStyling = styled.div`
    padding-left: 1rem;
`


const AlignDashboard:React.FC = ()=>{
    const {getAppUser} = useStore()
    const appUserData = getAppUser()

    return (
        <DashboardStyling>
            <h4>Welcome {appUserData.name}!</h4>
            <h5>Dashboard coming soon.</h5>
        </DashboardStyling>
    )
}

export default AlignDashboard
