import { useEffect, useState, useContext, Dispatch, SetStateAction, useRef } from 'react'
import { GridCellProps } from '@progress/kendo-react-grid'
import { TextBox, TextBoxChangeEvent } from '@progress/kendo-react-inputs'
import * as Checkbox from '@radix-ui/react-checkbox'
import styled from 'styled-components'
import AlignLink from './AlignLink'
import { IListManagerListItem } from '../models/ListManager'
import { IUser, ROLES } from '../models/UserManager'
import { ROLES_READABLE, rolesArray } from '../models/UserManager'
import AlignButton from './AlignButton'
import { DialogContext } from './AlignDialog'

export const CustomInputTextCell = (data:GridCellProps)=>{
    //console.log(data, 'data at the inputCellText')
    const [cellData, setCellData] = useState(data)
    const fieldKey = cellData.field?cellData.field:''
    const [textInputData, setTextInputData] = useState(cellData.dataItem[fieldKey])

    useEffect(()=>{
        setCellData(data)
        setTextInputData(cellData.dataItem[fieldKey])
        //console.log('did data changed at input level?', data)
    },[data, fieldKey, cellData.dataItem])

    const updateFieldText = (e:TextBoxChangeEvent)=>{
        setTextInputData(e.value)
    }

    const handleChange = (e:any)=>{
        if (data.onChange) {
            data.onChange({
                dataIndex: cellData.dataIndex,
                dataItem: cellData.dataItem,
                field: cellData.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value,
            })
        }
    }
    
    return (
        <td>
            {cellData.dataItem.inEdit?(
                <TextBox
                    value={textInputData}
                    onBlur={handleChange}
                    onChange={updateFieldText}
                />
            ):(
                cellData.dataItem.is_heading?<strong>{cellData.dataItem[fieldKey]}</strong>:cellData.dataItem[fieldKey]
            )}
        </td>
    )
}

interface ICustomCheckBoxElement {
    gridProps:GridCellProps
    handleChange:(e:any,data?:any)=>void
    checkBoxCheckedValue?:string
    multiCheckBoxLabel?:string
    allCheckBoxesCheckedValues?:string[]
}

//Individual Checkboxes not used in dialogs, can use the gridProps directly. Using multiple checkboxes requires a local state
//to handle value changes, and when finished we propagate those changes to the grid at once, at dialog dismissal.
export const CustomCheckBoxElement:React.FC<ICustomCheckBoxElement> = (props)=>{
    const fieldName = props.gridProps.field || ''
    const labelText:string = props.multiCheckBoxLabel? props.multiCheckBoxLabel:fieldName? `${props.gridProps.dataItem[fieldName]}`:''
    const labelTitleCase:string = labelText.length !== 0?`${labelText.charAt(0).toUpperCase()}${labelText.slice(1)}`:labelText
    const fieldIdentifier:string = props.multiCheckBoxLabel?props.multiCheckBoxLabel.replace(/\s+/g, ''):props.gridProps.id

    const isChecked = ()=>{ 
        let isChecked = false
        if(props.checkBoxCheckedValue){
            let listOfOptions:string[] = props.allCheckBoxesCheckedValues || []
            listOfOptions.some((value)=>{
                if(props.checkBoxCheckedValue && props.checkBoxCheckedValue === value){
                    isChecked = true
                    return true
                }
                return false
            })
        }else{
            if(typeof props.gridProps.dataItem[fieldName] === 'boolean'){
                isChecked = props.gridProps.dataItem[fieldName]
            }
        }
        return isChecked
    }

    return (
        <>
            {fieldName !== undefined? (
                <div className="w-checkbox checkbox-field">
                    <Checkbox.Root 
                        className={`CheckboxRoot w-checkbox-input w-checkbox-input--inputType-custom checkbox ${isChecked()?'w--redirected-checked':''}`} 
                        checked={isChecked()} 
                        id={`${fieldIdentifier}`}
                        onCheckedChange={(e)=>props.handleChange(e,props.checkBoxCheckedValue)}
                    />
                    <label className="checkbox-label w-form-label" htmlFor={`${fieldIdentifier}`}>
                        {labelTitleCase}
                    </label>
                </div>)
                :(
                   null 
                )}
        </>
    )
}

export const CustomCheckBoxCell = (props:GridCellProps)=>{
    const fieldName = props.field
    const handleChange = (e:any)=>{
        if (props.onChange) {
            props.onChange({
                dataIndex: props.dataIndex,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e
            })
        }
    }
    const labelText:string = fieldName? `${props.dataItem[fieldName]}`:''
    const labelTitleCase:string = labelText.length !== 0?`${labelText.charAt(0).toUpperCase()}${labelText.slice(1)}`:labelText
    //console.log(fieldName,'fieldname at checkbox? ')
    return (
        //Checkbox 'checked' indicator is driven by Css webflow which is why there is no <Checkbox.Indicator> component inside
        // <Checkbox.Root>. 
        <td>
            {props.dataItem.inEdit && fieldName ? (
               <CustomCheckBoxElement gridProps={props} handleChange={handleChange}/>
            ):(
                <span>{labelTitleCase}</span>
            )}
        </td>
    )
}

interface IMultiCheckBoxesControl {
    inputCheckedValues:string[]
    checkboxesAllValues:string[]
    setOutPutCheckedValues:Dispatch<SetStateAction<string[]>>
    gridCellProps:GridCellProps

}

const MultiCheckboxesControl:React.FC<IMultiCheckBoxesControl> = (multiCheckProps)=>{
    const {checkboxesAllValues, inputCheckedValues, setOutPutCheckedValues, gridCellProps} = multiCheckProps
    const fieldName = gridCellProps.field
    const allValues = checkboxesAllValues as ROLES[]
    const [allCheckedValues, setAllCheckedValues] = useState(inputCheckedValues)
    const handleLocalChange = (e:any,data:any)=>{
        if(allCheckedValues.includes(data)){
            //if item already exists, then its a removal
            let idx = allCheckedValues.findIndex((element)=> element === data)
            let tmpArray = [...allCheckedValues]
            tmpArray.splice(idx,1)
            setAllCheckedValues(tmpArray)
            setOutPutCheckedValues(tmpArray)
        } else {
            let tmpArray = [...allCheckedValues]
            tmpArray.push(data)
            setAllCheckedValues(tmpArray)
            setOutPutCheckedValues(tmpArray)
        }
    }
    const CheckBoxesFragment = allValues.map((item,index)=>{
        return (<div key={index} className='list-value'>
            <CustomCheckBoxElement 
                gridProps={gridCellProps} 
                handleChange={handleLocalChange} 
                checkBoxCheckedValue={item} 
                multiCheckBoxLabel={fieldName === 'roles'?`${ROLES_READABLE[item]}`:`${item}`}
                allCheckBoxesCheckedValues={allCheckedValues}
            /> 
        </div>)
    })


    return (
        <div>
            {CheckBoxesFragment}
        </div>
    )
}

const MultiItemEditorWrap = styled.div`
    display: flex;
    div.actions {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        align-items: center;
    }
`

export const CustomMultiCheckBoxInputCell = (props:GridCellProps)=>{
    const fieldName = props.field
    //[Todo] this only uses the "roles" as this is the only field we have that uses multiple values so we 
    //are hard coding this because of no API backend. 
    const allOptions = (fieldName === 'roles'?rolesArray:undefined)
    const items:[]|undefined = props.dataItem[fieldName?fieldName:'']
    const [outPutCheckedItems,setOutPutCheckedValues] = useState(items?items:[''])
    const dismissalOutPutCheckedItems = useRef([''])
   
    useEffect(()=>{
        //console.log('outPutCheckedItems', outPutCheckedItems)
        dismissalOutPutCheckedItems.current = outPutCheckedItems
    },[outPutCheckedItems])

    
   
    const handleChange = (e:any,data:any)=>{
        if (props.onChange) {
            props.onChange({
                dataIndex: props.dataIndex,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: data
            })
        }
    }

    const saveDialogBoxChanges = (e:any)=>{
        //console.log('will save edits.', dismissalOutPutCheckedItems)
        handleChange(e, dismissalOutPutCheckedItems.current)
    }

    const listItems:JSX.Element[] | JSX.Element = (
        items?
            items.map((item, index)=>{
                    return (
                        <div key={index} className='list-value'>
                            {fieldName === 'roles'?`${ROLES_READABLE[item]}`:`${item}`}
                        </div>
                    )
            })
        :
            (<></>)
        )
    
    const {openDialog, closeDialog} = useContext(DialogContext)
    

    return(
        <td>
            {props.dataItem.inEdit && fieldName ? (
                <MultiItemEditorWrap>
                    <div className='values'>{listItems}</div>
                    <div className='actions'>
                        <AlignButton 
                            label='Edit'
                            onClick={()=>{
                                openDialog(
                                    <div className='dialog-content-wrap'>
                                        <h5 className='dialog-header'>
                                            {`Edit ${fieldName}`}
                                        </h5>
                                        <MultiCheckboxesControl
                                            checkboxesAllValues={allOptions?allOptions:[]}
                                            inputCheckedValues={props.dataItem.roles}
                                            setOutPutCheckedValues={setOutPutCheckedValues}
                                            gridCellProps={props}
                                        />
                                        <div className='dialog-button-actions'>
                                            <AlignButton label='Cancel' onClick={()=>{closeDialog()}}/>
                                            <AlignButton label='Save' onClick={(e:any)=>{
                                                //console.log(e)
                                                saveDialogBoxChanges(e)
                                                closeDialog()
                                                }}/>
                                        </div>
                                    </div>
                                )
                                }
                            }
                            style={{
                                lineHeight: 1,
                                height: 'fit-content'
                            }}

                        />
                    </div>
                </MultiItemEditorWrap>
            ):(
                <>
                    {listItems}
                </>
            )}
        </td>
    )
}


const MoveCheckboxCellStyling = styled.td`
&&&.cellPadding {
    padding: 0;
    padding-top: 3px;
    padding-bottom: 3px;
}
.visuallyHidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
`

interface ICustomSelectCheckBoxCell {
indexUpdateCallback?:(props?:any)=>void
numberOfItems?:number
pageSkip?:number
}

export interface IMoveElementEvent {
actionName:string
dataItem:IListManagerListItem | IUser
}

export const MoveCheckBoxCell = (props:GridCellProps, options:ICustomSelectCheckBoxCell)=>{
const fieldName = props.field
const {indexUpdateCallback, numberOfItems, pageSkip} = options

const handleChange = (e:any)=>{
    if (props.onChange) {
        props.onChange({
            dataIndex: props.dataIndex,
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e
        })
    }
}

const notFirstItem = props.dataIndex !== 0
const isLastItem = numberOfItems && pageSkip?(props.dataIndex >= ((numberOfItems+pageSkip)-1)):undefined

return (
    //Checkbox 'checked' indicator is driven by Css webflow which is why there is no <Checkbox.Indicator> component inside
    // <Checkbox.Root>. 
        <MoveCheckboxCellStyling className='cellPadding'>
            {fieldName && (
                <div className="w-checkbox checkbox-field" style={{flexDirection:'column'}}>
                    <Checkbox.Root 
                        className={`CheckboxRoot w-checkbox-input w-checkbox-input--inputType-custom checkbox ${props.dataItem[fieldName]?'w--redirected-checked':''}`} 
                        checked={props.dataItem[fieldName]} 
                        id={`${props.id}`}
                        onCheckedChange={handleChange}
                        disabled={props.dataItem.inEdit?false:true}
                    />
                    <label className="visuallyHidden" htmlFor={`${props.id}`}>
                        Move Row
                    </label>
                    {props.dataItem[fieldName] && notFirstItem && <div style={{paddingTop:'8px', width:'100%', marginLeft:'-20px'}}>
                        <AlignLink
                            label='Up'
                            materialIconName='arrow_upward'
                            iconLeft={true}
                            onClick={()=>{
                                let dataItem:IMoveElementEvent = {actionName:'up',dataItem:props.dataItem}
                                indexUpdateCallback && indexUpdateCallback(dataItem)
                            }}
                            style={{marginLeft:'-10px'}}
                        />
                    </div>}
                    {props.dataItem[fieldName] && !isLastItem && <div style={{paddingTop:'8px', width:'100%', marginLeft:'-20px'}}>
                        <AlignLink
                            label='Down'
                            materialIconName='arrow_downward'
                            iconLeft={true}
                            onClick={()=>{
                                let dataItem:IMoveElementEvent = {actionName:'down',dataItem:props.dataItem}
                                indexUpdateCallback && indexUpdateCallback(dataItem)
                            }}
                            style={{marginLeft:'-10px'}}
                        />
                    </div>}
                </div>
            )}
        </MoveCheckboxCellStyling>
    )
}
