import React from 'react'
import ReactDOM from 'react-dom/client'
import '@material-design-icons/font/filled.css';
import '@material-design-icons/font/outlined.css';
import './css/normalize.css'
import './css/webflow.css'
import './css/idesign-systemflow-v-1-4.webflow.css'
import './css/index.css'
import reportWebVitals from './reportWebVitals'

//import DemoForm from './routes/DemoForm'
import AxiosInstanceProvider from './api/AxiosInstance'
//import ApiStatus from './routes/ApiStatus'

import { createGlobalStyle } from 'styled-components'
import colors from './css/shared-styles'
import Gate from './Gate'



const GlobalStyle = createGlobalStyle`
  body {
    div.k-animation-container.k-animation-container-relative div.k-list-container div.k-list div.k-list-content ul.k-list-ul li.k-list-item.k-selected, div.k-animation-container.k-animation-container-relative.k-animation-container-shown div.k-list-container div.k-list div.k-list-content ul.k-list-ul li.k-list-item.k-selected {
      background-color: ${colors.linkBlue}
    }
  }
`

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <AxiosInstanceProvider
      config={{ baseURL: "https://xtot-nirm-3igr.n7.xano.io" }}
    >
      <Gate />
    </AxiosInstanceProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
