//single file for all paths to the API

export enum API_PATHS {
    PROGRAM_DATA = '/api:Q5UCnBcM/programs/program',
    LISTS_BY_ORGANIZATION ='/api:J6LhVvXz/lists',
    LISTS_HEADER_DATA = '/api:WXhntzIx/list',
    LIST_TYPES='/api:WXhntzIx/list_types',
    LOGIN='/api:Q5UCnBcM/auth/login',
    LOGOUT='/api:Q5UCnBcM/auth/logout',
    IS_LOGGED_IN='/api:Q5UCnBcM/auth/isloggedin'
}

