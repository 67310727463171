import React, { PropsWithChildren, useContext } from 'react'
import {Grid, GridColumn as Column, GridCellProps, GridToolbar} from '@progress/kendo-react-grid'
import {IListManagerListItem } from '../models/ListManager'
import { IUser } from '../models/UserManager'
import { MoveCheckBoxCell, IMoveElementEvent} from './AlignListGridCustomInputCells'
import AlignButton from './AlignButton'
import {v4 as uuidv4} from 'uuid'
import { DialogContext } from './AlignDialog'

export type AlignListItemTypes = IListManagerListItem | IUser
export interface IPageState {
    skip: number
    take: number
}

export const pageDefault = {skip: 0, take: 25 }

interface IAlignListGrid<Type extends unknown> {
    pagedGridData:Type[]
    listItemChange:(event:any)=>void
    editField:string
    page:IPageState
    gridData:Type[]
    setGridData:React.Dispatch<React.SetStateAction<any>>
    pageChange:(event:any)=>void
    setPagedGridData:(value: React.SetStateAction<any>) => void
    readOnlyMode:boolean
    gridDataType:'AdminUserItems'|'ListManagerItems'
    deleteItemConfirmDialogTitle?:string
}

/**
 * @description Generic grid component intended for when data to be displayed on it is of a flat list shape, it is possible to enhance this component into 
 * displaying detail on a given row but the intention is that will be responsibility of the view consuming this component, we will be adding a couple sorting
 * filtering and other features to this grid. This grid has a "view only" and "edit" modes in combination with a pager providing a display optimization 
 * specially for the edit mode.
 * @param props.pagedGridData: resulting slice array of type IListManagerItem[] or IUser[] data currently displayed at the grid for the current "page" when the user uses the paging
 * controls the pagedGridData is updated accordingly.
 * @param props.listItemChange: callback provided by the useAlignListGridEditingUtilities hook that most be implemented at the component consuming this grid (parent view)
 * @param props.editField: string constant usually provided by the useAlignListGridEditingUtilities hook but can be overwritten if needed to another value.
 * @param props.page: IPageState object with the latest paging state provided by the parent view component.
 * @param props.gridData: full data set array provided by parent (IListManagerItem[] or IUser[]).
 * @param props.setGridData: callback to mutate the full data set provided by the parent.
 * @param props.pageChange: callback provided by the parent view and called every time a page event occurs at this component.
 * @param props.setPagedGridData: callback provided by the parent to update the pagedGridData array.
 * @param props.readOnlyMode: boolean provided by the parent that switches between read only and edit modes of the grid.
 * @param props.children: accepts children of any of the types used by the Telerik react grid (eg. Column, Row) we currently assume only GridColumn will be used, those
 * children get injected to both read only and editable mode grids.
 * @returns an enhanced Progress Telerik grid <Grid> component displayed at it's parent component. 
 */

const AlignListGrid:React.FC <IAlignListGrid<AlignListItemTypes> & PropsWithChildren>=(props)=>{
    const {pagedGridData, listItemChange, editField, page, gridData, setGridData, pageChange, setPagedGridData, readOnlyMode, deleteItemConfirmDialogTitle, gridDataType} = props
    const {openDialog, closeDialog} = useContext(DialogContext)
    //Just for debug purposes.
    /*useEffect(()=>{
        console.log('page grid data update/refresh')
    },[pagedGridData])*/

    const SelectMoveCheckBoxCell: React.FC<GridCellProps> = (props) => {
        let callback = (propsData:IMoveElementEvent)=>{
            const {dataItem, actionName} = propsData
            let currentGridData = [...pagedGridData]
            let moveItemIndex = currentGridData.indexOf(dataItem)
            let moveTargetIndex = -1
            if(actionName === 'up'){
                moveTargetIndex = moveItemIndex - 1   
            }else if(actionName === 'down'){
                moveTargetIndex = moveItemIndex + 1
            }
            if(moveTargetIndex !== -1){
                if((moveTargetIndex >= 0) || (moveTargetIndex <= (pagedGridData.length-1))){
                    let gridDataItemToMove = currentGridData.splice(moveItemIndex, 1)
                    currentGridData.splice(moveTargetIndex,0,gridDataItemToMove[0])
                    //console.log('setting moved rows data', currentGridData)
                    setPagedGridData(currentGridData)
                }
            }
        }
        let numberOfItems:number = pagedGridData.length
        return MoveCheckBoxCell(props, {indexUpdateCallback:callback, numberOfItems:numberOfItems, pageSkip:page.skip})
    }

    const deleteItem = (itemId:string)=>{
        let itemIndex = gridData.findIndex((item)=>{return item.id === itemId})
        if(itemIndex !== -1){
            let updatedGridData:AlignListItemTypes[] = []
            if(itemIndex !== 0){
                //prefix part of data
                updatedGridData.push(...gridData.slice(0,itemIndex))
                //suffix part of data
                updatedGridData.push(...gridData.slice(itemIndex+1))
            }else if(itemIndex === 0){
                updatedGridData.push(...gridData.slice(itemIndex+1))
            }

            setGridData(updatedGridData)
            //console.log('deleted item', itemId, ' updated data',updatedGridData)
        }
    }

    const addItem = ()=>{
        //[Todo] this will work if there is at least one item on the list, if not we need to create
        //another method at the model level to instantiate a mock object.
        let newItemClone = JSON.parse(JSON.stringify(gridData[0]))

        for (const [key, value] of Object.entries(newItemClone)) {
            //setup default object
            switch (typeof value) {
                case 'string' :
                    newItemClone[key] = 'New item'
                    break;
                case 'boolean' :
                    newItemClone[key] = false
                    break;
                case 'object' :
                    if(newItemClone[key] !== null){
                        newItemClone[key] = []
                    }
                    break
            }
            if(key === 'id'){
                newItemClone[key] = uuidv4()
            }
        }

        //console.log(newItemClone, 'newItemClone')
        let upgradedGridData:AlignListItemTypes[] = []
        upgradedGridData.push(newItemClone)
        upgradedGridData.push(...gridData)
        setGridData(upgradedGridData)
        //console.log('added item', newListItem.id)
    }
    //console.log(pagedGridData, 'pagedGridData')
    return (
        <>
            {readOnlyMode && <Grid
                data={pagedGridData}
                onItemChange={listItemChange}
                editField={editField}
                skip={page.skip}
                take={page.take}
                total={gridData.length}
                pageable={{info:true, pageSizes:[10,25,50]}}
                onPageChange={pageChange}
                className="align-list-manager-grid"
            >
                {props.children}
            </Grid>}
            {!readOnlyMode && <Grid
                data={pagedGridData}
                onItemChange={listItemChange}
                editField={editField}
                skip={page.skip}
                take={page.take}
                total={gridData.length}
                pageable={{info:true, pageSizes:[10,25,50]}}
                onPageChange={pageChange}
                className="align-list-manager-grid"
            >
                <GridToolbar>
                    <AlignButton 
                        label='Add List Item'
                        onClick={()=>{
                            addItem()
                        }}
                        style={{marginLeft: 'auto'}}
                    />
                </GridToolbar>
                <Column 
                    title="Move"
                    field="move"
                    cell={SelectMoveCheckBoxCell}
                    width="60px"
                />
                {props.children}
                <Column cell={(data:GridCellProps)=>{
                    return (
                        <td>
                            <AlignButton 
                                label='Delete'
                                onClick={()=>{
                                    openDialog(
                                        <div className='dialog-content-wrap'>
                                            <h5 className='dialog-header'>
                                                {deleteItemConfirmDialogTitle?deleteItemConfirmDialogTitle:'Delete Item?'}
                                            </h5>
                                            {gridDataType === 'AdminUserItems' && 
                                                <p className='body-text m'>
                                                    {`${data.dataItem.firstName} ${data.dataItem.lastName} will be permanently deleted.`}
                                                </p>
                                            }
                                            <div className='dialog-button-actions'>
                                                <AlignButton label='Cancel' onClick={()=>{closeDialog()}}/>
                                                <AlignButton label='Delete' onClick={()=>{deleteItem(data.dataItem.id)}}/>
                                            </div>
                                        </div>
                                )}}
                                style={{
                                    lineHeight: 1
                                }}
                            />
                        </td>
                    )}}
                    title="Delete"
                    width="100px"
                />
            </Grid>}
        </>
    )
}

export default AlignListGrid
