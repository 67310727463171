
const colors = {
    lightGrey: '#bbbbbb',
    loginGrey: '#ebebeb',
    linkBlue: '#00a5e1',
    selectedBlue:'rgb(0 165 225 / 20%)',
    white: '#ffffff',
    formButtonBlue: '#017FAE',
    formButtonBackGrey: '#E6EAEC'
}

export default colors

// this file should be used to keep a single point of reference for style rules so it becomes easy to update in the future
