import {useState, useContext, useMemo, useRef} from 'react'
import {useStore} from '../models/AlignEditor'
import {AxiosContext} from '../api/AxiosInstance'
import axios from 'axios'


const useDrawerListLoader = () => {
    const {initDrawerList, drawerListsReady} = useStore()
    const contextInstance = useContext(AxiosContext)
    const [error, setError] = useState<string|unknown>('')
    const [currentListId, setCurrentListId] = useState<number>(-1)
    const doOnce = useRef(false)
    const axiosInstance = useMemo(() => {
        return contextInstance || axios;
    }, [contextInstance])
    const controllerRef = useRef(new AbortController())
    
    const loadList = (updatedListId:number) =>{
        const path = `/api:J6LhVvXz/demo/list/${updatedListId}`
        if(!doOnce.current){
            doOnce.current = true
            const fetchData = async ()=> {
                try {
                const response = await axiosInstance.request({
                    signal: controllerRef.current.signal,
                    url:path,
                    method:'GET'
                })
                if (response.status === 200) {
                    setCurrentListId(initDrawerList(response.data))
                    doOnce.current = false
                }
                } catch ( theError) {
                    console.log('error??', theError)
                    doOnce.current = false
                setError(theError)
                throw theError
                } finally {
                //console.log('finally.')
                } 
            }
            if(updatedListId !== -1){
                fetchData()
            }
        }
    }

    const isDrawerListsReady = ()=>{
        return drawerListsReady
    }

    const getCurrentListId = ()=>{
        return currentListId
    }

    const resetLoadFlag = ()=>{
        doOnce.current = false
    }

    return {isDrawerListsReady, getCurrentListId, error, loadList, resetLoadFlag}
}

export default useDrawerListLoader
