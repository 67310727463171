import React, {useState, createContext, PropsWithChildren} from "react"
import styled from 'styled-components'
import colors from "../css/shared-styles"
import AlignDrawerListLoader from './AlignDrawerListsLoader'


const DrawerContentWrap = styled.div`
   display: flex;
   flex: 1;
   overflow: hidden;
`

const DrawerStyling = styled.div`
    position: relative;
    display: flex;
    .main-content {
        display: flex;
        flex: 1;
    }
    .drawer {
        position: absolute;
        top: 0;
        right: 0;
        width: 400px;
        display: flex;
        height: 100%;
        flex-direction: column;
        background-color: ${colors.white};
        border-left: 1px solid lightgray;
        z-index: 1;
        .close-button {
            margin: .5rem;
            align-self: flex-start;
        }
    }
`

type IDrawerType = {
    setIsDrawerOpen:React.Dispatch<React.SetStateAction<boolean>>,
    useListSelector?:boolean,
    setUseListSelector?:React.Dispatch<React.SetStateAction<boolean>>,
    useCloseBtn?:boolean
    organizationId?:number
    setOrganizationId?:React.Dispatch<React.SetStateAction<number>>
}
export const DrawerContext = createContext<IDrawerType>({setIsDrawerOpen:()=>{}})

const AlignDrawer: React.FC<PropsWithChildren> = props => {
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
    const [useListSelector, setUseListSelector] = useState<boolean>(false)
    // [Todo] revisit this as organizationId should come from the logged in user (root model)
    // having it here this way is not practical, but since users are still under dev that is why.
    const [organizationId, setOrganizationId] = useState<number>(-1)

    const handleClick = () => {
        setIsDrawerOpen(!isDrawerOpen)
    }


    return (
        <DrawerContext.Provider value={{
            setIsDrawerOpen:setIsDrawerOpen,
            useListSelector:useListSelector, 
            setUseListSelector:setUseListSelector,
            setOrganizationId:setOrganizationId,
            organizationId:organizationId
        }}>
            <DrawerStyling className="drawer-wrap">
                <div className="main-content">
                    {props.children}
                </div>
                {isDrawerOpen && <div className="drawer">
                    <button
                        className="button small outline w-inline-block close-button"
                        onClick={handleClick}
                    >
                        Close
                    </button>
                    <DrawerContentWrap className="drawer-content-wrap">
                        {useListSelector && organizationId !== -1 && <AlignDrawerListLoader organization_id={organizationId}/>}
                    </DrawerContentWrap>
                </div>}
            </DrawerStyling>
        </DrawerContext.Provider>
    )
}

export default AlignDrawer
