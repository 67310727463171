import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import {useStore} from '../models/AlignEditor'
import { useReactFlow } from 'reactflow'
import { getReadableTextColorForBackground } from '../utilities/TextContrastCalc'

const AlignNodeLegendsStyles = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    z-index: 5;
    .color-pick-input {
        padding-top: .5rem;
        padding-bottom: .5rem;
        padding-right: 1rem;
    }
`

const AlignNodeLegends:React.FC = (props) => {
    const {setColor, getCurrentColors, isColorsReady} =  useStore()
    const {getNodes, setNodes} = useReactFlow()
    const [legendControls, setLegendControls] = useState<JSX.Element[]|undefined>()
    
    const updateColor = useCallback((colorType:string, newColor:string)=>{
        //update color in the model
        setColor(colorType, newColor)
        //get displayed nodes and update the ones with the new color
        setNodes(
            Array.from(
                getNodes().map((node)=>{
                    if(node.data.type === colorType){
                        node.style = {
                            backgroundColor:newColor,
                            color: getReadableTextColorForBackground(newColor) || "#000000"
                        } 
                    }
                    return node
                })
            )
        )
    },[getNodes, setColor, setNodes])

    useEffect(()=>{
        if(!legendControls){
            setLegendControls(
                getCurrentColors().map((color, index)=>{
                    return ( 
                        <div className='color-pick-input' key={index}>
                            <div 
                                className='annotation-label' 
                                style={{ color: '#000'}}
                            >
                                {color.nodeType}
                            </div>
                            <input
                                type="color"
                                defaultValue={color.color}
                                onChange={(evt) => console.log('changed', evt)}
                                onBlur={(evt)=>{updateColor(color.nodeType, evt.target.value)}}
                                className="nodrag"
                            />
                        </div>
                    )
                })
            )
        }

    },[legendControls, getCurrentColors, updateColor])

    
    return (
        <AlignNodeLegendsStyles>
            {isColorsReady() && legendControls}
        </AlignNodeLegendsStyles>
    )
}

export default AlignNodeLegends
