import React, { useState } from 'react'
import {useStore, ProgramTreeNodeInstance, IProgramTreeNode} from '../models/AlignEditor'
import '@progress/kendo-theme-default/dist/all.css'
import { 
        Grid, 
        GridColumn as Column,
        GridExpandChangeEvent,
        GridCellProps
    } from '@progress/kendo-react-grid'
import DetailProgramCourses from './DetailProgramCourses'
import styled from 'styled-components'
// Sample on how to implement/call a dialog if needed.
//import { DialogContext } from './AlignDialog'

const CurriculumStylesWrap = styled.div`
    display: flex;
    div.k-widget.k-grid {
        overflow-y: scroll;
        overflow-x: hidden;
        div.k-grid-container {
            overflow: initial;
        }
    }
`

interface IAlignCurriculumDataGrid {
}

//Dialog example, you can modify and place inside Return
/*
    <button onClick={()=>{openDialog(
        <>
            <p>hi I am just a simple paragraph</p>
        </>
    )}}>test dialog</button>
*/

const AlignCurriculumDataGrid: React.FC<IAlignCurriculumDataGrid> = (props) => {
    const {getCurrentSnapshot, toggleProgramExpandById} = useStore()
    //const {openDialog} = useContext(DialogContext)
    const [data, setData] = useState<IProgramTreeNode[]>(getCurrentSnapshot());

    const expandChange = (event:GridExpandChangeEvent)=>{
        const node = event.dataItem as ProgramTreeNodeInstance
        //toggle program expand at the model
        toggleProgramExpandById(node.id)
        //get the new snapshot and apply to grid
        setData(getCurrentSnapshot())
    }
    return (
        <CurriculumStylesWrap className='curriculum-grid-style'>
            <Grid
                style={{}}
                data={data}
                detail={DetailProgramCourses}
                expandField="expanded"
                onExpandChange={expandChange}
            >
                <Column field="display_name" title="Program Name" />
                <Column cell={(data:GridCellProps)=>{
                    const dateToFormat = new Date(data.dataItem.created_at)
                    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' } as const

                    return (<td>
                        {dateToFormat.toLocaleDateString("en-US", options)} {dateToFormat.toLocaleTimeString("en-US")}
                    </td>)
                    }} title="Created At"/>
                <Column cell={(data:GridCellProps)=>{
                    const dateToFormat = new Date(data.dataItem.updated_at)
                    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' } as const

                    return (<td>
                        {dateToFormat.toLocaleDateString("en-US", options)} {dateToFormat.toLocaleTimeString("en-US")}
                    </td>)
                    }} title="Updated At"/>
            </Grid>
        </CurriculumStylesWrap>
    )
}

export default AlignCurriculumDataGrid
