import React from "react"
import Register from "../components/Register"

const RegisterRoute: React.FC = props => {
    return (
        <Register />
    )
}

export default RegisterRoute
