import React, {useState, useContext, useEffect, useRef} from 'react'
import '@progress/kendo-theme-default/dist/all.css'
import {useStore, CurriculumInstance, ICurriculum} from '../models/AlignEditor'
import { 
    Grid, 
    GridColumn as Column,
    GridDetailRowProps,
    GridCellProps,
    GridToolbar,
    GridExpandChangeEvent
} from '@progress/kendo-react-grid'
import styled from 'styled-components'
import useCurriculumTreeNodeEditingUtils from '../hooks/useCurriculumTreeNodeEditingUtils'
import useMappingEditingUtils from '../hooks/useObjectivesEditingUtilities'
import { DrawerContext } from '../components/AlignDrawer'
import DetailActivity from './DetailActivity'

const ToolbarStyling = styled.div`
    button {
        margin-bottom: 0;
    }
`

interface IDetailModuleActivities {
    programId:number|string
}

const DetailModulesActivities:React.FC<GridDetailRowProps & IDetailModuleActivities> = (props)=>{
    const {getModuleActivities, createModuleActivity, saveModuleActivityChanges, deleteActivity:deleteModuleActivity, getModuleMappings, saveModuleMappings, deleteModuleMapping, getCurrentProgramUid, toggleExpandActivity} = useStore()
    const programId = getCurrentProgramUid()
    const module = props.dataItem as CurriculumInstance
    const courseUid = module.parent_uid? module.parent_uid:0
    const {setIsDrawerOpen} = useContext(DrawerContext)
    const [dataActivities, setDataActivities] = useState<ICurriculum[]>(courseUid?getModuleActivities(programId, module.uid):[])
    const dataActivitiesInitialLength = useRef(dataActivities.length)
    const [activityChanges, setActivityChanges] = useState<boolean>(false)
    const {customCellRender:activityCellRender, customRowRender:activityRowRender, EDIT_FIELD:activityEDIT_FIELD, itemChange: activityItemChange} = useCurriculumTreeNodeEditingUtils(dataActivities, activityChanges, setDataActivities, setActivityChanges)
    const [mappingChanges, setMappingChanges] = useState<boolean>(false)
    const [dataMappings, setDataMappings] = useState(programId?getModuleMappings(programId, module.uid):[])
    const {mappingCellRender, mappingRowRender, EDIT_FIELD:mappingEDIT_FIELD, mappingItemChange} = useMappingEditingUtils(dataMappings, mappingChanges, setDataMappings, setMappingChanges)
    const [tempAddedActivities, setTempAddedActivities] = useState<ICurriculum[]>([]) 

    useEffect(()=>{
        if(activityChanges){
            if(dataActivities.length > dataActivitiesInitialLength.current){
                //assumes the last added element is always new, this could change.
                let lastAdded = dataActivities.slice(-1)
                let duplicate = tempAddedActivities.find((activity)=>{return activity.uid === lastAdded[0].uid})
                if(!duplicate){
                    setTempAddedActivities([...tempAddedActivities, ...lastAdded])
                }
            }
        }
    },[dataActivities, activityChanges, tempAddedActivities])


    const saveActivityChanges = () => {
        setDataActivities(saveModuleActivityChanges(programId,module.uid,dataActivities))
        setActivityChanges(false);
    }

    const cancelActivityChanges = () => {
        if(tempAddedActivities.length !== 0){
            //cleanup unused activities
            tempAddedActivities.forEach((activity)=>{
                deleteModuleActivity(programId,activity.uid)
            })
            setTempAddedActivities([])
        }
        setDataActivities(getModuleActivities(programId, module.uid))
        setActivityChanges(false);
    }

    const addNewActivity = () => {
        if(activityChanges){
            alert('There are active changes, please save or cancel and try again.')
        }else{
            createModuleActivity(programId, module.uid)
            setDataActivities(getModuleActivities(programId, module.uid))
        }
    }

    const deleteActivity = (activityUid:string)=>{
        //console.log(programId, activityUid, ' delete activity clicked')
        deleteModuleActivity(programId,activityUid)

        setDataActivities(getModuleActivities(programId, module.uid))

    }

    const saveMappingChanges = () => {
        setDataMappings(saveModuleMappings(programId, module.uid, dataMappings))
        setMappingChanges(false);
    }

    const cancelMappingChanges = () => {
        setDataMappings(programId?getModuleMappings(programId, module.uid):[]);
        setMappingChanges(false);
    }

    const addNewMapping = () => {
        setIsDrawerOpen(true)
    }

    const deleteMapping = (mappingUid:string)=>{
        deleteModuleMapping(programId, module.uid, mappingUid)
        setDataMappings(programId?getModuleMappings(programId, module.uid):[]);
    }

    const expandChange = (event:GridExpandChangeEvent)=>{
        const activity = event.dataItem as CurriculumInstance
        // toggle module expand at the model
        if(programId){
            toggleExpandActivity(programId, module.uid, activity.uid )
            //get the new snapshot and apply to this grid
            setDataActivities(getModuleActivities(programId,module.uid))
        }
    }

    /* TODO: "Mappings" is the new name for "Objectives", most things were updated to Mappings but not all, at some point we need to ma sure to change all.*/

    return (
        <>
            <ToolbarStyling className='mappings-style'>
                <Grid
                    data={dataMappings}
                    onItemChange={mappingItemChange}
                    cellRender={mappingCellRender}
                    rowRender={mappingRowRender}
                    editField={mappingEDIT_FIELD}
                >
                    <GridToolbar>
                        <h6>Module Mappings</h6>
                        <button 
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={addNewMapping}
                        >
                            Add Mapping
                        </button>
                        {mappingChanges && <button
                            title="Save"
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={saveMappingChanges}
                                >
                            Save Changes
                        </button>}
                        {mappingChanges && <button
                            title="Cancel Changes"
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={cancelMappingChanges}
                                >
                            Cancel Changes
                        </button>}
                    </GridToolbar>
                    <Column field="objective" title="Mapping" editor='text' />
                    <Column cell={(data:GridCellProps)=>{
                    return (<td><button onClick={()=>(window.confirm('Delete the objective?')?deleteMapping(data.dataItem.id):false)}>Delete</button></td>)
                    }} title="Delete Mapping"/>
                </Grid>
            </ToolbarStyling>
            <ToolbarStyling>
                <Grid
                    data={dataActivities}
                    onItemChange={activityItemChange}
                    cellRender={activityCellRender}
                    rowRender={activityRowRender}
                    editField={activityEDIT_FIELD}
                    detail={DetailActivity}
                    expandField="expanded"
                    onExpandChange={expandChange}
                >   
                    <GridToolbar>
                        <h6>Activities </h6>
                        <button 
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={addNewActivity}
                        >
                            Add Activity
                        </button>
                        {activityChanges && <button
                            title="Save"
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={saveActivityChanges}
                        >
                            Save Changes
                        </button>}
                        {activityChanges && <button
                            title="Cancel Course Activity Changes"
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={cancelActivityChanges}
                        >
                            Cancel Changes
                        </button>}
                    </GridToolbar>
                    <Column field="display_name" title="Name" />
                    <Column field="number_of_credits" title="Credits" editor="numeric" />
                    <Column field="tot_minutes" title="Total Minutes" editor="numeric" />
                    <Column cell={(data:GridCellProps)=>{
                        return (<td><button onClick={()=>(window.confirm('Delete the activity? there is no undo.')?deleteActivity(data.dataItem.uid):false)}>Delete</button></td>)
                        }} title="Delete Activity"/>
                </Grid>
            </ToolbarStyling>
        </>
    )
}

export default DetailModulesActivities
