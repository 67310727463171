
export const getReadableDate = (milliseconds:number)=>{
    let readableDate = new Date(milliseconds).toLocaleDateString(
        'en-gb',
        {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        }
    )
    return readableDate
}
