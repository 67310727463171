import React, { useState} from 'react'
import {useStore, ICurriculum, IProgramTreeNode, CurriculumInstance} from '../models/AlignEditor'
import '@progress/kendo-theme-default/dist/all.css'
import { 
    Grid, 
    GridColumn as Column,
    GridDetailRowProps,
    GridExpandChangeEvent,
    GridToolbar
} from '@progress/kendo-react-grid'
import DetailCourseModules from './DetailCourseModules'
import styled from 'styled-components'
import useCurriculumTreeNodeEditingUtils from '../hooks/useCurriculumTreeNodeEditingUtils'

const CustomGridStyles = styled.div`
    button {
        margin-bottom: 0;
    }
`

const DetailProgramCourses:React.FC<GridDetailRowProps> = (props)=>{
    const {getProgramCourses, toggleExpandProgramCourse, saveProgramCourseChanges, createProgramCourse} = useStore()
    const program = props.dataItem as IProgramTreeNode
    const [data, setData] = useState<ICurriculum[]>(getProgramCourses(program.uid))
    const [changes, setChanges] = useState<boolean>(false)
    const {customCellRender, customRowRender, EDIT_FIELD, itemChange} = useCurriculumTreeNodeEditingUtils(data, changes, setData, setChanges)
    

    const saveChanges = () => {
        setData(saveProgramCourseChanges(program.id, data))
        setChanges(false);
    }

    const cancelChanges = () => {
        setData(getProgramCourses(program.id));
        setChanges(false);
    }

    const addNewCourse = () => {
        if(changes){
            alert('There are active changes, please save or cancel and try again.')
        }else{
            createProgramCourse(program.uid)
            setData(getProgramCourses(program.uid))
        }
    }

    const expandChange = (event:GridExpandChangeEvent)=>{
        if(!changes){
            const course = event.dataItem as CurriculumInstance
            //toggle course expand at the model
            toggleExpandProgramCourse(program.id, course.uid)
            //get the new snapshot and apply to this grid
            setData(getProgramCourses(program.id))
        }
    }

    return (
        <CustomGridStyles>
            <Grid
                data={data}
                detail={DetailCourseModules}
                expandField="expanded"
                onExpandChange={expandChange}
                onItemChange={itemChange}
                cellRender={customCellRender}
                rowRender={customRowRender}
                editField={EDIT_FIELD}
            >
                <GridToolbar>
                    <h6 style={{marginTop:'0.5rem'}}>Courses </h6>
                    <button 
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                        onClick={addNewCourse}
                    >
                        Add Course
                    </button>
                    {changes && <button
                        title="Save"
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                        onClick={saveChanges}
                            >
                        Save Changes
                    </button>}
                    {changes && <button
                        title="Cancel Course Changes"
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                        onClick={cancelChanges}
                            >
                        Cancel Changes
                    </button>}
                </GridToolbar>
                <Column field="display_name" title="Name" />
                <Column field="number_of_credits" title="Credits" editor="numeric" width={100}/>
                <Column field="tot_minutes" title="Total Mins" width={100}/>
            </Grid>
        </CustomGridStyles>
    )
}

export default DetailProgramCourses
