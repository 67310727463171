import React, {createContext, useContext} from 'react'
import { Dialog } from '@reach/dialog'
import styled from 'styled-components'

const DialogInstancePlusStyle = styled(Dialog)`
    min-width: 500px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    .dialog-button-actions {
        display: flex;
        justify-content: flex-end;
        button {
            margin-right: 1rem;
            &:last-child {
                margin-right: 0;
            }
        }
    }
`

export interface IAlignDialogContext {
    isOpen:boolean
    dialogContent:JSX.Element
    setDialogContent:React.Dispatch<React.SetStateAction<JSX.Element>>
    openDialog:(element?:JSX.Element)=>void
    closeDialog:()=>void
}

export interface IAlignDialog {
    children: JSX.Element | JSX.Element[] 
}

/**
 * @description An AlignDialog context instance
 * @param isOpen: boolean
 * @param dialogContent: JSX.Element to be used as the displayed content of the dialog
 * @param setDialogContent: Callback to be used at the invoking component to set the dialog content
 * @param openDialog: Callback that opens the dialog once the content has been set
 * @param closeDialog: Callback to close the dialog instance
 */

export const DialogContext = createContext<IAlignDialogContext>(
    {
        isOpen:false, 
        dialogContent:<></>, 
        setDialogContent:()=>{}, 
        openDialog:()=>{}, 
        closeDialog:()=>{}
    })

/**
 * 
 * @description A Dialog component that acts as a HOC that wraps a passed component child to be displayed, we have a single instance as sibling of
 * the main application component, that way when we invoke the dialog it will be the 'one-and-only' and we are able to capture focus to enable accessibility
 * via keyboard etc. We are using React's context mechanism to call the dialog anywhere it's needed and form any component under the App tree.
 * @param props.children:JSX.Element or elements.
 * @returns DialogInstancePlusStyle -- an instance of a ReachUI Dialog wrapped via Styled Components 
 */

const AlignDialog: React.FC<IAlignDialog> = (props)=>{
    const {isOpen, dialogContent, closeDialog} = useContext(DialogContext)

    const onDismiss = ()=>{
        closeDialog()
    }

    return (
            <DialogInstancePlusStyle
            isOpen={isOpen}
            onDismiss={onDismiss}
            aria-label="Warning about next steps"
            >
               {dialogContent}
            </DialogInstancePlusStyle>
    )
}

export default AlignDialog
