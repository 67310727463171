import { createContext, useContext } from 'react'
import { types, Instance, getSnapshot, applySnapshot, SnapshotIn, resolveIdentifier} from "mobx-state-tree"
import ListManager, {IListManagerList, ListManagerListItem } from './ListManager'
//destroy, onSnapshot
//import sampleData from './AlignEditorNewData'
import { toJS } from 'mobx'
import { getReadableTextColorForBackground } from '../utilities/TextContrastCalc'
import {v4 as uuidv4} from 'uuid'

enum NodeTypes {
    Program = 'Program',
    Course = 'Course',
    Module = 'Module',
    Activity = 'Activity'
}

const Color = types.model('Colors',{
    id: types.identifier,
    nodeType: types.string,
    color: types.string
}).actions(self => ({
    updateColor(color:string){
        self.color = color
    }
}))

export type ColorsInstance = Instance<ListManagerListItem>
export interface IColor extends SnapshotIn<typeof Color>{}

export const getUniqueId=()=>{
    return uuidv4()
}

const AvailableListId = types.model('AvailableListIds',{
    list_id: types.maybeNull(types.number)
})

export type AvailableListIdsInstance = Instance<typeof AvailableListId>
export interface IAvailableListIds extends SnapshotIn<typeof AvailableListId>{}

const MappingList = types.model('MappingList',{
    lists_id: types.number,
    list_name: types.string,
    list_type: types.string
})

export type MappingListInstance = Instance<typeof MappingList>
export interface IMappingList extends SnapshotIn<typeof MappingList>{}

const MappingListItem = types.model('MappingListItem',{
    list_id: types.number,
    list_name: types.string,
    list_item_id: types.string,
    list_item_description: types.string
})

export type MappingListItemInstance = Instance<typeof MappingListItem>
export interface IMappingListItem extends SnapshotIn<typeof MappingListItem>{}

const Curriculum = types.model('Curriculum',{
    uid: types.string,
    parent_uid: types.maybeNull(types.string),
    code: types.string,
    name: types.string,
    display_name: types.string,
    item_label: types.string,
    item_type: types.string,
    number_of_credits: types.optional(types.number,0),
    tot_minutes: types.optional(types.number,0),
    mapped_list_items: types.optional(types.array(MappingListItem),[]),
    expanded: types.optional(types.boolean,false)
}).actions(self => ({
    toggleExpand(){
        self.expanded = !self.expanded
    },
    addMapping(mappingItem:IMappingListItem){
        self.mapped_list_items.push(mappingItem)
    },
    deleteMapping(mappingUid:string){
        let mappedListItemIndex = self.mapped_list_items.findIndex((mapping)=>mapping.list_item_id === mappingUid)
        if(mappedListItemIndex !== -1){
            self.mapped_list_items.splice(mappedListItemIndex, 1)
        }
    },
    updateCurriculum(data:ICurriculum){
        self.parent_uid = data.parent_uid?data.parent_uid:null
        self.code = data.code
        self.name = data.name
        self.display_name = data.display_name
        self.number_of_credits = data.number_of_credits?data.number_of_credits:0
        self.tot_minutes = data.tot_minutes?data.tot_minutes:0
        applySnapshot(self.mapped_list_items,data.mapped_list_items?data.mapped_list_items:[])
    }
}))

export type CurriculumInstance = Instance<typeof Curriculum>
export interface ICurriculum extends SnapshotIn<typeof Curriculum>{}

const ProgramTreeNode = types.model('ProgramTreeNode', {
    id: types.identifierNumber,
    uid: types.string,
    organization_id: types.number,
    display_name: types.string,
    code: types.string,
    name: types.string,
    total_credits: types.maybeNull(types.number),
    total_tot_minutes: types.maybeNull(types.number),
    created_at: types.number,
    updated_at: types.number,
    available_lists: types.maybeNull(types.array(AvailableListId)),
    mapped_lists: types.maybeNull(types.array(MappingList)),
    expanded: types.optional(types.boolean,false),
    curriculum: types.optional(types.array(Curriculum),[])
}).actions(self => ({
    toggleExpand(){
        self.expanded = !self.expanded
    },
    addCurriculum(child:ICurriculum){
        self.curriculum.push(child)
    },
    getCurriculumIndex(curriculumUid:string){
        let curriculumIndex = self.curriculum.findIndex((curriculum:CurriculumInstance)=>curriculum.uid === curriculumUid)
        return curriculumIndex
    },
    deleteCurriculum(curriculumUid:string){
        let index = this.getCurriculumIndex(curriculumUid)
        //remove item
        if(index !== -1){
            self.curriculum.splice(index,1)
        }
    },
    addCurriculumMapping(curriculumUid:string, mapping:IMappingListItem){
        let index = this.getCurriculumIndex(curriculumUid)
        if(index !== -1){
            self.curriculum[index].mapped_list_items.push(mapping)
        }
    },
    deleteCurriculumMapping(curriculumUid:string, mappingUid:string){
        let index = this.getCurriculumIndex(curriculumUid)
        if(index !== -1){
            let mappedListItemIndex = self.curriculum[index].mapped_list_items.findIndex((mapping)=>mapping.list_item_id === mappingUid)
            if(mappedListItemIndex !== -1){
                self.curriculum[index].mapped_list_items.splice(mappedListItemIndex, 1)
            }
        }
    }
}))

export type ProgramTreeNodeInstance = Instance<typeof ProgramTreeNode>
export interface IProgramTreeNode extends SnapshotIn<typeof ProgramTreeNode>{}

// for future use after refactor of models.
/*interface IAdditionalActivity extends IProgramTreeNode {
    parentType?:string
}*/

type NodeData = {
    label: string
    color?:string
    type?:string
}
export type Position = {
    x:number
    y:number
}
export type INode = {
    id: string
    data: NodeData
    position: Position
    type?:string
    style?:{}
}

export type IEdge = {
    id:string
    source:string
    target:string
    animated?:boolean
    label?: string
}

const AlignEditor = types.model('AlignEditor', {
    programTreeNode: types.array(ProgramTreeNode),
    listManager: types.optional(ListManager,{}),
    drawerLists: types.optional(ListManager,{}),
    colors: types.array(Color),
    storeReady: types.optional(types.boolean, false),
    listManagerReady: types.optional(types.boolean, false),
    drawerListsReady: types.optional(types.boolean, false),
    colorsReady:types.optional(types.boolean, false),
    currentProgramUid:types.optional(types.string,''),
    currentOrganizationId:types.optional(types.number, -1)
}).views(self => ({
    getProgramById(id:number){
        return resolveIdentifier(ProgramTreeNode, self, id)
    },
    getProgramByUid(uid:string){
        let programTreeNode:ProgramTreeNodeInstance | undefined = undefined
        programTreeNode = self.programTreeNode.find((program)=>{return program.uid === uid})
        return programTreeNode
    },
    getProgram(programId:string|number){
        //this solves the issue where sometimes we get a program by id or uid
        let program:ProgramTreeNodeInstance | undefined = undefined

        if(typeof(programId) === 'number'){
            program = this.getProgramById(Number(programId))
        }else{
            program = this.getProgramByUid(String(programId))
        }
        //console.log('setting current program Organization Id to:', program, program && program.organization_id)
        //this sets the id or uid for the current program, used as a short code.
        self.currentOrganizationId = (program && program.organization_id?program.organization_id:-1)
        self.currentProgramUid = (program && program.uid?program.uid:'')
        return program
    },
    getCurrentProgramUid(){
        return self.currentProgramUid
    },
    getCurrentOrganizationId(){
        return self.currentOrganizationId
    },
    isStoreReady(){
        return self.storeReady
    },
    isListManagerReady(){
        return self.listManagerReady
    },
    isColorsReady(){
        return self.colorsReady
    },
    getNodeColor(nodeType:string){
        let nodeColor: string = ''
        self.colors.every((color)=>{
            if(nodeType === color.nodeType){
                nodeColor = color.color
                return false
            }
            return true
        })
        return nodeColor
    },
    getCurrentColors(){
        return toJS(self.colors)
    }
  })).actions(self => ({
    initProgramTreeNode(data:IProgramTreeNode[]){

        applySnapshot(self.programTreeNode, data)
        self.storeReady = true
    },
    initListManager(data:IListManagerList){
        self.listManagerReady = true
        return self.listManager.initListManagerData(data)
    },
    resetListManager(){
        self.listManagerReady = false
    },
    listManagerInstance(){
        return self.listManager
    },
    initDrawerList(data:IListManagerList){
        self.drawerListsReady = true
        return self.drawerLists.initListManagerData(data)
    },
    listDrawerInstance(){
        return self.drawerLists
    },
    resetAll(){
        let defaultSnapshot = getSnapshot(AlignEditor.create())
        applySnapshot(self, defaultSnapshot)
    },
    getCurrentSnapshot(){
        return getSnapshot(self.programTreeNode)
    },
    toggleProgramExpandById(programId:number){
        self.programTreeNode.every((node)=>{
            if(node.id === programId){
                node.toggleExpand()
                return false
            }
            return true
        })
    },
    getProgramCourses(programId:number|string){
        let courses:ICurriculum[] = []
        let program = self.getProgram(programId)
        if(program){
            courses = program.curriculum.filter((curriculum)=>{return curriculum.item_type === NodeTypes.Course})
        }
        
        return courses
    },
    toggleExpandProgramCourse(programId:number, courseUid:string){

        let program:ProgramTreeNodeInstance | undefined = undefined
        program = self.getProgram(programId)
        if(!program){
            return false
        }
        program.curriculum.every((node)=>{
            if(node.item_type === NodeTypes.Course && node.uid === courseUid){
                node.toggleExpand()
                return false
            }
            return true
        })
    },
    getCourseModules(programId:number|string,courseUid:string){
        let modules:ICurriculum[] = []
        let program = self.getProgram(programId)
        
        if(!program){
            return []
        } else {
            modules = program.curriculum.filter((node)=>{
                return node.item_type === NodeTypes.Module && node.parent_uid === courseUid
            })

            return modules
        }
    },
    getCourseMappings(programId:number|string,courseUid:string){
        let mappings:IMappingListItem[] = []
        let program = self.getProgram(programId)
        program && program.curriculum.every((node)=>{
            if(node.item_type === NodeTypes.Course && node.uid === courseUid){
                mappings.push(...node.mapped_list_items)
                return false
            }
            return true
        })
        return mappings
    },
    saveCourseMappings(programId:number|string, courseUid:string, updateData:IMappingListItem[]){
        let mappings:IMappingListItem[] = []
        let program = self.getProgram(programId)
        
        program && program.curriculum.every((node)=>{
            if(node.item_type === NodeTypes.Course && node.uid === courseUid){
                applySnapshot(node.mapped_list_items, updateData)
                mappings = getSnapshot(node.mapped_list_items)
                return false
            }
            return true
        })
        return mappings
    },
    addCourseMapping(programId:number, courseUid:string, mapping_item:IMappingListItem){
        let program = self.getProgram(programId)
        if(!program){
            return false
        }
        program.curriculum.every((node)=>{
            if(node.item_type === NodeTypes.Course && node.uid === courseUid){
                node.addMapping(mapping_item)
                return false
            }
            return true
        })
    },
    deleteCourseMapping(programId:number|string, courseUid:string, mappingUid:string){
        let program = self.getProgram(programId)
        
        program && program.curriculum.every((node)=>{
            if(node.item_type === NodeTypes.Course && node.uid === courseUid){
                node.deleteMapping(mappingUid)
                return false
            }
            return true
        })
    },
    toggleExpandCourseModule(programId:number|string, courseUid:string, moduleUid:string){
        let program = self.getProgram(programId)
        program && program.curriculum.every((node)=>{
            if(node.item_type === NodeTypes.Module && node.uid === moduleUid && node.parent_uid === courseUid){
                node.toggleExpand()
                return false
            }
            return true
        })
    },
    saveProgramCourseChanges(programId:number, updateData:ICurriculum[]){
        let program = self.getProgram(programId)
        let updatedCourses:ICurriculum[] = []

        updateData.forEach((updatedCourse)=>{
            let courseToUpdate = program?.curriculum.find((node)=>{return node.uid === updatedCourse.uid})
            courseToUpdate && courseToUpdate.updateCurriculum(updatedCourse)
        })
        let updatedCoursesTmp = program?.curriculum.filter((node)=>{return node.item_type === NodeTypes.Course})
        updatedCourses = updatedCoursesTmp?updatedCoursesTmp:[]

        return updatedCourses
    },
    createProgramCourse(programId:number|string){
        let program = self.getProgram(programId)
        
        if(program){
            let courseTmp = {
                uid: getUniqueId(),
                parent_uid: program.uid,
                code: NodeTypes.Course,
                name: NodeTypes.Course,
                display_name: 'New Course',
                item_label: NodeTypes.Course,
                item_type: NodeTypes.Course,
            }
            program.addCurriculum(courseTmp)
        }
    },
    //Modules
    getModule(programId:number, moduleUid:string){
        let module:ICurriculum | undefined = undefined
        let program:ProgramTreeNodeInstance | undefined = undefined
        program = self.getProgramById(programId)
        if(program !== undefined){
            program.curriculum.every((node)=>{
                if(node.item_type === NodeTypes.Module && node.uid === moduleUid){
                    module = getSnapshot(node)
                    return false
                }
                return true
            })
        }
        if(module !== undefined){
            return module as ICurriculum
        }
        return module
    },
    getModuleMappings(programId:string|number, moduleUid:string){
        let mappings:IMappingListItem[] = []
        let program = self.getProgram(programId)
        program && program.curriculum.every((node)=>{
            if(node.item_type === NodeTypes.Module && node.uid === moduleUid){
                mappings.push(...node.mapped_list_items)
                return false
            }
            return true
        })
        return mappings
    },
    addModuleMapping(programId:number, moduleUid:string, mapping_item:IMappingListItem){
        let program:ProgramTreeNodeInstance | undefined = undefined
        program = self.getProgramById(programId)
        if(!program){
            return false
        }
        program.curriculum.every((node)=>{
            if(node.item_type === NodeTypes.Module && node.uid === moduleUid){
                node.addMapping(mapping_item)
                return false
            }
            return true
        })
    },
    saveModuleMappings(programId:number|string, moduleUid:string, updateData:IMappingListItem[]){
        let mappings:IMappingListItem[] = []
        let program = self.getProgram(programId)
        
        program && program.curriculum.every((node)=>{
            if(node.item_type === NodeTypes.Module && node.uid === moduleUid){
                applySnapshot(node.mapped_list_items, updateData)
                mappings = getSnapshot(node.mapped_list_items)
                return false
            }
            return true
        })
        return mappings
    },
    createModuleActivity(programId:number|string, moduleUid:string){
        let program = self.getProgram(programId)
        let parentModule = program?.curriculum.find((module)=>{return module.item_type === NodeTypes.Module && module.uid === moduleUid})
        
        if(program){
            let activityTmp:ICurriculum = {
                uid: getUniqueId(),
                parent_uid: moduleUid,
                code: parentModule?.code || NodeTypes.Activity,
                name: NodeTypes.Activity,
                display_name: `${parentModule?.code}: New Activity`,
                item_label: NodeTypes.Activity,
                item_type: NodeTypes.Activity
            }
            program.addCurriculum(activityTmp)
        }
    },
    getModuleActivities(programId:number|string, moduleUid:string){
        let activities:ICurriculum[] = []
        let program:ProgramTreeNodeInstance | undefined = undefined
        program = self.getProgram(programId)
        //console.log('get module activities? ', 'program?', program, 'moduleUid:', moduleUid)
        let moduleActivities = program && program.curriculum.filter((node)=>{return node.item_type === NodeTypes.Activity && node.parent_uid === moduleUid})
        if(moduleActivities){
            activities = moduleActivities
        }
        return activities
    },
    toggleExpandActivity(programId:number|string, moduleUid:string, activityUid:string){
        let program = self.getProgram(programId)
        program && program.curriculum.every((node)=>{
            if(node.item_type === NodeTypes.Activity && node.uid === activityUid && node.parent_uid === moduleUid){
                node.toggleExpand()
                return false
            }
            return true
        })
    },
    saveModuleActivityChanges(programId:number|string, moduleUid:string, updatedActivities:ICurriculum[]){
        let updated_activities:ICurriculum[] = []
        let program:ProgramTreeNodeInstance | undefined = undefined
        program = self.getProgram(programId)
        console.log(updatedActivities, 'updated activities')
         
        if(program){
            updatedActivities.forEach((activity)=>{
                let foundActivity = program?.curriculum.find((node)=>{return node.item_type === NodeTypes.Activity && node.uid === activity.uid})
                if(foundActivity){
                    foundActivity.updateCurriculum(activity)
                }
            })
        }

        let module_updated_activities = program && program.curriculum.filter((activity)=>{return activity.item_type === NodeTypes.Activity && activity.parent_uid === moduleUid})

        console.log('module_updated_activities', module_updated_activities)
        if(module_updated_activities){
            updated_activities = module_updated_activities
        }
        return updated_activities
    },
    deleteActivity(programId:number|string, activityUid:string){
        let program:ProgramTreeNodeInstance | undefined = undefined
        program = self.getProgram(programId)
        program && program.deleteCurriculum(activityUid)
    },
    deleteModuleMapping(programId:number|string, moduleUid:string, mappingUid:string){
        let program:ProgramTreeNodeInstance | undefined = undefined
        program = self.getProgram(programId)
        
        program && program.curriculum.every((node)=>{
            if(node.item_type === NodeTypes.Module && node.uid === moduleUid){
                node.deleteMapping(mappingUid)
                return false
            }
            return true
        })
    },
    saveActivityMappings(programId:number|string, activityUid:string, updateData:IMappingListItem[]){
        let mappings:IMappingListItem[] = []
        let program = self.getProgram(programId)
        
        program && program.curriculum.every((node)=>{
            if(node.item_type === NodeTypes.Activity && node.uid === activityUid){
                applySnapshot(node.mapped_list_items, updateData)
                mappings = getSnapshot(node.mapped_list_items)
                return false
            }
            return true
        })
        return mappings
    },
    getActivityMappings(programId:string|number, activityUid:string){
        let mappings:IMappingListItem[] = []
        let program = self.getProgram(programId)
        program && program.curriculum.every((node)=>{
            if(node.item_type === NodeTypes.Activity && node.uid === activityUid){
                mappings.push(...node.mapped_list_items)
                return false
            }
            return true
        })
        return mappings
    },
    deleteActivityMapping(programId:number|string, activityUid:string, mappingUid:string){
        let program = self.getProgram(programId)
        
        program && program.curriculum.every((node)=>{
            if(node.item_type === NodeTypes.Activity && node.uid === activityUid){
                node.deleteMapping(mappingUid)
                return false
            }
            return true
        })
    },
    //End mappings.
    getFlowNodes(programId:number){
        //console.log('getFlow called...')
        let flowNodes:INode[] = []
        let flowEdges:IEdge[] = []
        let currentProgram:IProgramTreeNode | undefined = self.getProgram(programId)
        if(currentProgram){
            //get courses
            let programCourses = currentProgram.curriculum && currentProgram.curriculum.filter((node)=>{return node.item_type === NodeTypes.Course})
            //get modules
            //let programModules = currentProgram.curriculum && currentProgram.curriculum.filter((node)=>{return node.item_type === NodeTypes.Module})
            //get activities - disabled for now.
            //let programActivities = currentProgram.curriculum && currentProgram.curriculum.filter((node)=>{return node.item_type === NodeTypes.Activity})
           
            const initY = 0
            const initX = 100
            const defaultTileWidth = 150
            const defaultTileHeight = 150
            const defaultPadding = 18
            const rowYStart = initY + defaultTileHeight + defaultPadding
            let rowNumber = 1
            let initialTotalCoursesWidth = ((programCourses?programCourses?.length:initX)*(defaultPadding + defaultTileWidth))
            let programNodePosX = (initialTotalCoursesWidth/2)+((defaultTileWidth/2)+defaultPadding)-(defaultTileWidth/2)
            let nodeTemplate:INode = {
                id: `${currentProgram.id}`,
                data: {label: currentProgram.display_name, type:NodeTypes.Program},
                position: {x: programNodePosX, y:initY},
                style: {
                    backgroundColor:self.getNodeColor('Program'), 
                    color:getReadableTextColorForBackground(self.getNodeColor('Program'))
                }
            }
            flowNodes.push(nodeTemplate)
            
            programCourses && programCourses.forEach((course:ICurriculum, index)=>{
                let curriculumNodeTemplate:INode = {
                    id: course.item_type+'-'+course.uid,
                    data: {label: course.display_name, type:course.item_type},
                    position: {x: (index*defaultTileWidth)+(index?initX+(index*defaultPadding):initX), y:rowNumber*rowYStart},
                    style: {
                        backgroundColor:self.getNodeColor(course.item_type),
                        color:getReadableTextColorForBackground(self.getNodeColor(course.item_type))
                    }
                }
                let courseEdge:IEdge = {
                    id: 'ed-'+course.uid,
                    source: (currentProgram?`${currentProgram.id}`:''),
                    target: course.item_type+'-'+course.uid
                }
                flowNodes.push(curriculumNodeTemplate)
                flowEdges.push(courseEdge)
            })
            
            //get course's modules
            rowNumber++
            let moduleColumnNumber = 0
            
            programCourses && programCourses.forEach((course:ICurriculum, index)=>{
                let courseModules = currentProgram && currentProgram.curriculum && currentProgram.curriculum.filter((node)=>{return node.item_type === NodeTypes.Module && node.parent_uid === course.uid})
                let moduleColumnRowNumber = rowNumber
                courseModules && courseModules.forEach((module)=>{
                    let moduleTemplate = {
                        id: module.item_type+'-'+module.uid,
                        data: {label: module.display_name, type:module.item_type},
                        position: {x: (moduleColumnNumber*defaultTileWidth)+(moduleColumnNumber?initX+(moduleColumnNumber*defaultPadding):initX), y:moduleColumnRowNumber*rowYStart},
                        style: {
                            backgroundColor:self.getNodeColor(module.item_type),
                            color:getReadableTextColorForBackground(self.getNodeColor(module.item_type))
                        }
                    }
                    let courseEdge:IEdge = {
                        id: `ed-${module.item_type}-${course.uid}-${module.uid}`,
                        source: course.item_type+'-'+course.uid,
                        target: module.item_type+'-'+module.uid
                    }
                    flowNodes.push(moduleTemplate)
                    flowEdges.push(courseEdge)
                    moduleColumnRowNumber++
                })
                moduleColumnNumber++
            })

            //create activities assumes activities in multiple levels of the tree (eg. courses, modules)
            rowNumber++
            //let activityColumnNumber = 0
            /*const createActivityNode = (activity:ICurriculum, nodePrefix:string, activityParent:ICurriculum)=>{
                let activityTemplate = {
                    id: activity.item_type+'-'+activity.uid,
                    data: {label: activity.display_name, type:activity.item_type},
                    position: {x: (activityColumnNumber*defaultTileWidth)+(activityColumnNumber?initX+(activityColumnNumber*defaultPadding):initX), y:rowNumber*rowYStart},
                    style: {
                        backgroundColor:self.getNodeColor(activity.item_type),
                        color:getReadableTextColorForBackground(self.getNodeColor(activity.item_type))
                    }
                }
                let activityEdge:IEdge = {
                    id: `ed-${nodePrefix}-${activity.uid}-${activity.uid}`,
                    source: activityParent.item_type+'-'+activity.parent_uid,
                    target: activity.item_type+'-'+activity.uid
                }
                flowNodes.push(activityTemplate)
                flowEdges.push(activityEdge)
                activityColumnNumber++
            }*/

            /* Disabling showing activities for now, as we need to update the way we will be showing these when a large
            number of them exist  */

            // get activities for courses
            /*programCourses && programCourses.forEach((course:ICurriculum)=>{
                let courseActivities = programActivities?.filter((activity)=>{return activity.parent_uid === course.uid})
                courseActivities && courseActivities.forEach((activity)=>{
                    createActivityNode(activity, NodeTypes.Course, course)
                })
            })
            // get activities for modules
            programModules && programModules.forEach((module)=>{
                let moduleActivities = programActivities?.filter((activity)=>{return activity.parent_uid === module.uid})
                moduleActivities && moduleActivities.forEach((activity)=>{
                    createActivityNode(activity,NodeTypes.Module, module)
                })
            }) */
        }
        return {nodes:flowNodes,edges:flowEdges}
    },
    setColor(colorType:string, colorvalue:string){
        self.colors.every((color)=>{
            if(color.nodeType === colorType){
                color.updateColor(colorvalue)
                console.log('updating color...')
                return false
            }
            return true
        })
        return true
    },
    initColors(colorData:IColor[]){
        applySnapshot(self.colors, colorData)
        self.colorsReady = true
    }
}))

export type AlignEditorInstance = Instance<typeof AlignEditor>
export interface IAlignEditor extends SnapshotIn<typeof AlignEditor> {}

export const alignEditorStore = AlignEditor.create()

//console.log(alignEditorStore, 'AlignEditorStore created.. ')
//use for listening to changes and debugging.
//onSnapshot(alignEditorStore, snapshot => console.log('Snapshop Updated...!.. ',snapshot))

export type AlignEditorStoreInstance = Instance<typeof alignEditorStore>

const AlignEditorStoreContext = createContext<AlignEditorStoreInstance>({} as AlignEditorStoreInstance)

export const Provider = AlignEditorStoreContext.Provider

export const useStore = () => {
    const store = useContext(AlignEditorStoreContext)
    if (store === null) {
        throw new Error('Store cannot be null, please add a context provider')
    }
    return store as AlignEditorStoreInstance
}

export type MapStore<T> = (store: AlignEditorStoreInstance) => T

export const useInject = <T>(mapStore: MapStore<T>) => {
    const store = useStore()
    return mapStore(store)
}
