import React, {useEffect, useState, useRef} from 'react'
import ListManagerLoader from '../components/ListManagerLoader'
import "@reach/menu-button/styles.css";
import { PaddingWrap } from '../components/SharedLayoutComponents'
import useAxios from '../hooks/useAxios'
import styled from 'styled-components'
import { API_PATHS } from '../api/ApiPaths'
import AlignDropDown, {ISelectItem} from '../components/AlignDropDown'

export interface IAvailableList {
    id: number
    created_at: number
    organization_id: number
    name: string
    list_type: string
    is_active: boolean
    text?:string
}

const DropDownCustomStyles = styled.div`
    margin-left: initial;
    min-width: 600px;
    padding-bottom: 1rem;
    width: min-content;
`

const ListManagerRoute: React.FC = props => {
    const apiFetched = useRef(false)
    const {data, error, fetch, requestUrl} = useAxios({method:"GET", initialValue:null})
    const [menuItems, setMenuItems] = useState<ISelectItem[]|null>(null)
    const [selectedListId, setSelectedListId] = useState<number|null>(null)

    const handleChange = (listItemName:string, listId:string) => {
        apiFetched.current = false
        setSelectedListId(Number(listId))
    }

    useEffect(()=>{
        if(!apiFetched.current){
            apiFetched.current = true
            requestUrl.current = `${API_PATHS.LISTS_HEADER_DATA}`
            fetch()
        }
        if(data && !menuItems){
            //apiFetched.current = true
            let dropDownMenuItems:ISelectItem[] = []
            data.forEach((item:IAvailableList)=>{
                dropDownMenuItems.push({
                    value:`${item.id}`,
                    label:item.name
                })
            })
            setMenuItems(dropDownMenuItems)
        }
    },[data, menuItems, fetch, apiFetched, requestUrl])

    return (
        <PaddingWrap className="hWrap">
            <p className="body-text m" style={{marginBottom:0}}>
                Select a list from the menu below to view and possibly edit. <i>Lists can be accreditation/standards, curriculum objectives, or other.</i>
            </p>
            <DropDownCustomStyles className="drop-down-custom-styles">
                {error === '' && menuItems && menuItems.length !== 0 &&
                    <AlignDropDown 
                        fieldName='list-loader'
                        handleValueChange={handleChange}
                        selectItems={menuItems}
                        labelAlign={'left'}
                    />
                }
                {error !== '' && <span>{JSON.stringify(error)}</span>}
            </DropDownCustomStyles>
            {selectedListId !== null && selectedListId !== -1 && <ListManagerLoader listId={selectedListId}/>}
        </PaddingWrap>
    )
}

export default ListManagerRoute
