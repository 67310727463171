import { types, Instance, SnapshotIn, applySnapshot, getSnapshot, ModelSnapshotType } from "mobx-state-tree"

export enum ListTypes {
    "LO"="Learning Objectives",
    "CC"="Core Competencies",
    "AB"="Advanced Behaviors",
    "WS"="Work Skills",
    "GL"="Global List"
}

export const listManagerItem = types.model('ListManagerItem', 
    {
        id: types.identifier,
        parent_id: types.maybeNull(types.string),
        description: types.string,
        is_heading: types.optional(types.boolean, false),
        is_mappable: types.optional(types.boolean, false),
        mapping_type_restrictions: types.optional(types.array(types.string),[]),
        move: types.optional(types.boolean, false)
    }
)

export type ListManagerListItem = Instance<typeof listManagerItem>
export interface IListManagerListItem extends SnapshotIn<typeof listManagerItem> {}

const listManagerList = types.model('ListManagerList',
    {
        id: types.identifierNumber,
        created_at: types.number,
        organization_id: types.number,
        name: types.string,
        list_type: types.string,
        is_active: types.boolean,
        items: types.optional(types.array(listManagerItem),[]),
        expanded: types.optional(types.boolean, false)
    }
)

export type ListManagerListInstance = Instance<typeof listManagerList>
export interface IListManagerList extends SnapshotIn<typeof listManagerList> {}

// eslint-disable-next-line
type ListManagerInput = {
    [key: string]: any
} & ListManagerListInstance

let lastSnapshot:ModelSnapshotType<{}>|null = null

const ListManager = types.model('ListManager',
    {
        list:types.optional(types.array(listManagerList), [])
    }
).views(self => ({
})).actions(self => ({
    initListManagerData(data:IListManagerList){
        applySnapshot(self.list, [data])
        return getSnapshot(self.list)[0].id
    },
    getCurrentSnapshot(){
        return getSnapshot(self.list)
    },
    getListItems(){
        return getSnapshot(self.list[0].items)
    },
    updateValue(fieldName:string, newValue:any){
        switch(fieldName){
            case 'organization_id': 
            break
            case 'name':
            break
            case 'list_type':
                self.list[0].list_type = newValue
            break
            case 'is_active':
                self.list[0].is_active = newValue
            break
            default:
        }
    },
    setLastSnapshot(){
        lastSnapshot = getSnapshot(self)
    },
    revertChanges(){
        if(lastSnapshot){
            applySnapshot(self, lastSnapshot)
            lastSnapshot = null
        }
        return this.getCurrentSnapshot()
    }
}))

export type ListManagerInstance = Instance<typeof ListManager>
export interface IListManager extends SnapshotIn<typeof ListManager> {}

export default ListManager
