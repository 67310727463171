
export const getReadableTextColorForBackground = (hexColorInput:string)=>{
    const threshold = 149;

    const hexToRgb = (hexColor:string|null)=> {
        if(hexColor){
            if(hexColor.length<5){
                // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
                let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
                hexColor = hexColor.replace(shorthandRegex, function(m, r, g, b) {
                    return r + r + g + g + b + b;
                })
            }
            
            let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor)
            
            return result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            } : null
        } else {
            return null
        }
    }

    const contrast =()=> {
        let r = hexToRgb(hexColorInput)?.r
        let g = hexToRgb(hexColorInput)?.g
        let b = hexToRgb(hexColorInput)?.b

        if(r !== undefined && g !== undefined && b !== undefined){
            return ((r*0.299 + g*0.587 + b*0.114) > threshold) ? '#000000' : '#ffffff'
        }else{
            return null
        }
    }

    return contrast()
}
