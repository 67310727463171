import React, {useState, useEffect} from 'react'
import * as Select from '@radix-ui/react-select'
import styled from 'styled-components'


const AlignDropDownStyles = styled.div`
    &.hover-lock:hover, &.hover-lock button:hover {
        cursor: not-allowed;
    }
    button {
        width: 100%;
    }
    .dropdown-button-text{
        &.left {
            justify-content: left;
            span[datatype="button-text"]{
                display: flex-inline;
                flex: 1;
                text-align: left;
            }
        }
    }
    .SelectTrigger:hover {
    }
    .SelectTrigger:focus {
    }
    .SelectTrigger[data-placeholder] {
    }
    
    .SelectIcon {
        display: flex;
        .material-icons {
            font-size: 1.25rem;
        }
    }
    
    .SelectViewport {
        padding: 5px;
    }  
`

const SelectContent = styled.div`
    /* useful style selector classes leaving them empty for future reference.*/
    .SelectItem {
    }
    .SelectItem[data-disabled] {
    }
    .SelectItem[data-highlighted] { 
    }
    .SelectLabel {
    }
    .SelectSeparator {
    }
    
    .SelectItemIndicator {
        .material-icons {
            display: flex;
        }
    }
    .SelectScrollButton {
    }
`

export interface ISelectItem {
    value:string
    label:string
}

interface IAlignDropDown {
    fieldName:string
    selectItems:ISelectItem[]
    handleValueChange:(fieldName:string, newValue:string)=>void
    value?:string
    readOnlyMode?:boolean
    labelAlign?:'left'
}

/**
 * @description Accessible drop down menu component using iDesign webflow styling
 * @param props.fieldName:string name for the drop down element form tracked name
 * @param props.selectItems:ISelectItem Array, eg. [{value:string, label:string}...]
 * @param props.handleValueChange:(fieldName:string, newValue:string)=>void callback triggered on drop down menu value changes.
 * @param props.value:string optional - initial value of the dropdown.
 * @param props.readOnlyMode:boolean optional, if true then dropdown will be read only, disabled.
 * @param props.labelAlign:'left' optional, label is usually displayed above unless left is used.
 * @returns 
 */

const AlignDropDown: React.FC<IAlignDropDown> = (props)=>{
    const {fieldName, selectItems, handleValueChange, value, readOnlyMode, labelAlign} = props
    const [defaultValue, setDefaultValue] = useState(value)

    useEffect(()=>{
        if(defaultValue !== value){
            setDefaultValue(value)
            //console.log('updating value at dropdown?', value)
        }
    },[value, defaultValue])

    const SelectItems = selectItems.map((item, index)=>{
        return (
            <Select.Item value={`${item.value}`} className='SelectItem dropdown-link-w-icon w-inline-block' key={index}>
                <Select.ItemIndicator className='SelectItemIndicator'>
                    <span className='material-icons dropdown-menu-item-icon'>check</span>
                </Select.ItemIndicator>
                <Select.ItemText className='button-text'>{item.label}</Select.ItemText>
            </Select.Item>
        )
    })

    return (
        <AlignDropDownStyles className={`align-drop-down ${readOnlyMode?'hover-lock':null}`}>
            <Select.Root
                value={defaultValue}
                defaultValue={defaultValue}
                onValueChange={(newValue)=>{handleValueChange(fieldName, newValue)}}
                disabled={readOnlyMode}
                
            >
                <Select.Trigger className="button medium outline w-dropdown-toggle" aria-label="Dropdown">
                    <div className={`dropdown-button-text ${labelAlign?labelAlign:''}`}>
                        <Select.Value datatype='button-text' placeholder="Select..."/>
                        <Select.Icon className="SelectIcon">
                            <span className='material-icons icon-right'>expand_more</span>
                        </Select.Icon>
                    </div>
                </Select.Trigger>
                <Select.Portal style={{zIndex:20}}>
                    <Select.Content style={{zIndex:1020}}>
                        <SelectContent className="SelectContent dropdown-menu-1 w-dropdown-list w--open">
                            <Select.ScrollUpButton className="SelectScrollButton">
                            <span className='material-icons'>expand_less</span>
                            </Select.ScrollUpButton>
                            <Select.Viewport className="SelectViewport">
                                {SelectItems}
                            </Select.Viewport>
                            <Select.ScrollDownButton className="SelectScrollButton">
                                <span className='material-icons'>expand_more</span>
                            </Select.ScrollDownButton>
                        </SelectContent>
                    </Select.Content>
                </Select.Portal>
            </Select.Root>
        </AlignDropDownStyles>
    )
}

export default AlignDropDown
