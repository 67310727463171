import React, {useEffect, useState, useRef} from 'react'
import "@reach/menu-button/styles.css";
import { PaddingWrap } from '../components/SharedLayoutComponents'
//import useAxios from '../hooks/useAxios'
import styled from 'styled-components'
//import { API_PATHS } from '../api/ApiPaths'
import AlignDropDown, {ISelectItem} from '../components/AlignDropDown'
import AdminView from "../components/AdminView"
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'

export interface IOrganization {
    id: number
    created_at: number
    name: string
    is_active: boolean
}

const DropDownCustomStyles = styled.div`
    margin-left: initial;
    min-width: auto;
    width: min-content;
`

/**
 * @description Load Organization menu options using a mock service (once we have an end point with it we will update the code to handle it.)
 */

const AdminRoute: React.FC = props => {
    const fetched = useRef(false)
    //const {data, error} = useAxios({url:`${API_PATHS.LISTS_HEADER_DATA}`, method:"GET", initialValue:null})
    const mock = new MockAdapter(axios)
    const [data, setData] = useState<IOrganization[] | undefined>()
    const error = ''
    const [menuItems, setMenuItems] = useState<ISelectItem[]|null>(null)
    const [selectedOrganizationId, setSelectedOrganizationId] = useState<number|null>(null)
    mock.onGet('/organizations').reply(200, {
        organizations: [
            {id:1, created_at:1675280675157, name:'iDesign', is_active:true},
            {id:2, created_at:1675280675157, name:'iDesign-TeamTwo', is_active:true}
        ]
    })
    useEffect(()=>{
        if(!data){
            axios.get('/organizations').then((response)=>{
                setData(response.data.organizations)
            })
        }
    },[data])

    const handleChange = (itemName:string, id:string) => {
        fetched.current = false
        setSelectedOrganizationId(Number(id))
    }

    useEffect(()=>{
        if(data && !menuItems){
            //console.log('populating menu data... ', data)
            fetched.current = true
            let dropDownMenuItems:ISelectItem[] = []
            data.forEach((item:IOrganization)=>{
                dropDownMenuItems.push({
                    value:`${item.id}`,
                    label:item.name
                })
            })
            setMenuItems(dropDownMenuItems)
        }
    },[data, menuItems])

    return (
        <PaddingWrap className="hWrap">
            <p className="body-text m" style={{marginBottom:'.5rem'}}>
                Select an Organization to view and mange its users
            </p>
            <DropDownCustomStyles className="drop-down-custom-styles">
                {error === '' && menuItems && menuItems.length !== 0 &&
                    <AlignDropDown 
                        fieldName='organization-loader'
                        handleValueChange={handleChange}
                        selectItems={menuItems}
                        labelAlign={'left'}
                    />
                }
                {error !== '' && <span>{JSON.stringify(error)}</span>}
            </DropDownCustomStyles>
            {selectedOrganizationId !== null && <AdminView organizationId={selectedOrganizationId}/>}
        </PaddingWrap>
    )
}

export default AdminRoute
