import React, {useEffect, useState, useRef} from "react"
import {
    DropDownList,
    DropDownListChangeEvent,
  } from "@progress/kendo-react-dropdowns"
import { PaddingWrap } from "./SharedLayoutComponents"
import useAxios from "../hooks/useAxios"
import useDrawerListLoader from "../hooks/useDrawerListLoader"
import AlignDrawerListGrid from "./AlignDrawerListGrid"
import { API_PATHS } from "../api/ApiPaths"

interface IAvailableList {
    id: number
    created_at: number
    organization_id: number
    name: string
    list_type: string
    is_active: boolean
    text?:string
}

interface IListsMenu {
    id: number
    text: string
}

interface IListLoader {
    organization_id?: number
}

const AlignDrawerListLoader: React.FC<IListLoader> = props => {
    const {organization_id} = props
    const menuDataFetched = useRef(false)
    const apiFetched = useRef(false)
    const {data, error, fetch, requestUrl} = useAxios({method:"GET", initialValue:null})
    const [availableLists, setAvailableLists] = useState<IListsMenu[]>([])
    const [selectedListId, setSelectedListId] = useState(-1)
    const [state, setState] = useState({
        value: {text:'Select...', id:-1}
    })
    const {isDrawerListsReady, getCurrentListId, error:errorTwo , loadList, resetLoadFlag} = useDrawerListLoader()

    useEffect(()=>{
        if(!apiFetched.current){
            apiFetched.current = true
            requestUrl.current = `${API_PATHS.LISTS_HEADER_DATA}`
            fetch()
        }
    })

    useEffect(()=>{
        if(state.value.text !== 'Select...' && !menuDataFetched.current){
            menuDataFetched.current = true
            setSelectedListId(state.value.id)
        }
    },[state])

    useEffect(()=>{
        let dataAvailableListItems:IAvailableList[] = []
        let availableListItems:IListsMenu[] = []
        if(data){
            dataAvailableListItems = data.filter((list:IAvailableList) => {
              return list.organization_id === organization_id && list.is_active === true  
            })
            dataAvailableListItems.forEach((listItem)=>{
                availableListItems.push({id:listItem.id, text:listItem.name})
            })
            setAvailableLists(availableListItems)
        }
    },[data, setAvailableLists, organization_id])

    useEffect(()=>{
        resetLoadFlag()
        loadList(selectedListId)
    },[selectedListId, loadList, resetLoadFlag])
    
    const handleChange = (event: DropDownListChangeEvent) => {
        menuDataFetched.current = false
        setState({
          value: event.target.value,
        })
    }

    return (
        <PaddingWrap className="hWrap" style={{height:'100%', width:'100%'}}>
            <p className="body-text s" style={{marginBottom:0}}>
                Select a list to use with the current program.
            </p>
            <div className="dropdown-fluid w-dropdown" style={{marginLeft: 'initial', minWidth:'100%', maxWidth: '100%'}}>
                {error === '' && availableLists.length !== 0 && <DropDownList
                    data={availableLists}
                    textField="text"
                    dataItemKey="id"
                    value={state.value}
                    onChange={handleChange}
                    className={'button-fluid medium outline w-dropdown-toggle'}
                />}
                {error !== '' && <span>{JSON.stringify(error)}</span>}
            </div>
            {isDrawerListsReady() && getCurrentListId() !== -1 && errorTwo === '' && <AlignDrawerListGrid listId={getCurrentListId()} />}
        </PaddingWrap>
    )
}

export default AlignDrawerListLoader
