import React, {useEffect, useCallback, useRef} from 'react'
import ReactFlow, { Controls, MiniMap, ReactFlowProvider, useNodesState, useEdgesState, addEdge} from 'reactflow'
import 'reactflow/dist/style.css'
import {IEdge, useStore} from '../models/AlignEditor'
import AlignColorPickerNode from './AlignNodeLegends'
import { observer } from 'mobx-react-lite'
import AlignNodeLegends from './AlignNodeLegends'

const nodeTypes = {alignColorPicker: AlignColorPickerNode}

const AlignFlow:React.FC = observer(()=>{
  const areNodesReady = useRef(false)
  const {getFlowNodes} = useStore()
  // eslint-disable-next-line
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  // eslint-disable-next-line
  const [edges, setEdges, onEdgesChange ] = useEdgesState<IEdge[]>([])

  useEffect(()=>{
    if(nodes.length === 0 && !areNodesReady.current){
      // hardcoded to program id 1 for now
      setNodes(getFlowNodes(1).nodes)
      setEdges(getFlowNodes(1).edges)
      areNodesReady.current = true
    }
  },[getFlowNodes, nodes, edges, setEdges, setNodes])

  const onConnect = useCallback((params:any) => setEdges((eds) => addEdge(params, eds)), [setEdges]);


  return (
    <div style={{ flex:1 }}>
      {nodes && nodes.length !== 0 && (
      <ReactFlowProvider>
        {nodes.length !== 0 && <ReactFlow 
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
        >
          <AlignNodeLegends />
          <Controls />
          <MiniMap nodeColor={'#33b7e7'} nodeStrokeWidth={3} zoomable pannable />
        </ReactFlow>}
      </ReactFlowProvider>
      )}
    </div>
  )
})

export default AlignFlow
