//template color data, need to add this to the back end at some point.

const colorData = {
    colors: [
        {
            id:'1',
            color:'#002e40',
            nodeType:'Program'
        },
        {
            id:'2',
            color:'#0095cb',
            nodeType:'Course'
        },
        {
            id:'3',
            color:'#33b7e7',
            nodeType:'Module'
        },
        {
            id:'4',
            color:'#99dbf3',
            nodeType:'Activity'
        }
    ]
}

export default colorData
