import React from 'react'
import AlignButton from './AlignButton'

interface IAlignEditModeButton {
    readOnlyMode:boolean
    setReadOnlyMode:React.Dispatch<React.SetStateAction<boolean>>
    enterEditMode:()=>void
    cancelChanges:()=>void
    saveChanges:()=>void
    onClickCallback?:()=>void
    alignRight?:boolean
    
}

const AlignEditModeButton:React.FC<IAlignEditModeButton> = (props)=>{
    const {readOnlyMode, setReadOnlyMode, enterEditMode, cancelChanges, saveChanges, onClickCallback, alignRight} = props

    return (
        <>
            {readOnlyMode && <AlignButton
                label='Edit'
                materialIconName='mode_edit'
                alignRight={alignRight}
                onClick={
                    ()=>{
                        setReadOnlyMode(!readOnlyMode);
                        enterEditMode()
                        if(onClickCallback){onClickCallback()}
                    }
                }
            />}
            {!readOnlyMode && <div style={{display:'flex'}}>
                <AlignButton
                    label='Cancel'
                    materialIconName='clear'
                    alignRight={true}
                    onClick={()=>cancelChanges()}
                />
                <AlignButton
                    label='Save'
                    materialIconName='done'
                    onClick={()=>saveChanges()}
                    style={{marginLeft:'.5rem'}}
                />
            </div>}
        </>
    )
}

export default AlignEditModeButton
