import { GridCellProps, GridRowProps } from '@progress/kendo-react-grid';
import { ICurriculum  } from '../models/AlignEditor';
import * as React from 'react';

type IDataItem = ICurriculum

interface ICellRenderProps {
    originalProps: GridCellProps,
    td:React.ReactElement<HTMLTableCellElement>,
    enterEdit: (dataItem: IDataItem, field: string | undefined | any) => void,
    editField: string | undefined
}

interface IRowRenderProps {
    originalProps: GridRowProps,
    tr:  React.ReactElement<HTMLTableRowElement>,
    exitEdit: () => void,
    editField: string | undefined
}

export const CellRender = (props: ICellRenderProps) => {
    const dataItem = props.originalProps.dataItem;
    const cellField = props.originalProps.field;
    const inEditField = dataItem[props.editField || ''];
    const additionalProps = cellField && cellField === inEditField ?
        {
            ref: (td: { querySelector: (arg0: string) => any }) => {
                const input = td && td.querySelector('input');
                const activeElement = document.activeElement;

                if (!input ||
                    !activeElement ||
                    input === activeElement ||
                    !activeElement.contains(input)) {
                    return;
                }

                if (input.type === 'checkbox') {
                    input.focus();
                } else {
                    input.select();
                }
            }
        } : {
            onClick: () => { props.enterEdit(dataItem, cellField); }
        };

    const clonedProps: any = { ...props.td.props, ...additionalProps };
    // @ts-expect-error
    return React.cloneElement(props.td, clonedProps , props.td.props.children);
}

export const RowRender = (props: IRowRenderProps)  => {
    const trProps = {
        ...props.tr.props,
        onBlur: () => {
            props.exitEdit();
        }
    };
    // @ts-expect-error
    return React.cloneElement(props.tr, { ...trProps }, props.tr.props.children);
}
