import React from "react"
import Login from "../components/Login"

const LoginRoute: React.FC = props => {
    return (
        <Login />
    )
}

export default LoginRoute
