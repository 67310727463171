import React, { useEffect, useRef } from 'react'
import defaultProfile from '../images/default-profile.png'
import { useUserManagerStore } from '../models/UserManager'
import { observer } from 'mobx-react-lite'
import { useStore as useRootStore } from '../models/Root'
import useAxios from '../hooks/useAxios'
import { API_PATHS } from '../api/ApiPaths'
import { DropDownButton, DropDownButtonItemClickEvent } from '@progress/kendo-react-buttons'
import styled from 'styled-components'
 
const ProfileDropdownStyles = styled.div`
    .profile-dropdown {
        border-radius: 50%;
    }
    .k-button-icon {
        height: 40px; 
        border-radius: 50%; 
    }
`

const ProfileDropdown:React.FC = observer(() => {
    const {logout: fakeLogout}=useUserManagerStore()
    const {setAuthToken, getAuthToken, resetAppUser}=useRootStore()
    const {data, error, requestUrl, authToken, fetch} = useAxios({method:'POST'})
    const apiFetched = useRef(false)

    const logout =()=>{
        authToken.current = getAuthToken()
        requestUrl.current = API_PATHS.LOGOUT
        fetch()
    }

    // [Todo] Implement an API call to fetch the users profile/agency photo instead of default

    useEffect(()=>{
        if(data || error){
            if(!apiFetched.current){
                apiFetched.current = true
                setAuthToken('')
                resetAppUser()
                fakeLogout()
                //console.log(data?data:error)
            }
        }
    },[data, error, fakeLogout, setAuthToken, resetAppUser])

    const profileDropdownList = [
        {
            text: "Align App v0.0.1",
            disabled: true,
            onClick: () => {
                return null 
            }
        },
        {
            text: "Log Out",
            onClick: () => {
                // console.log("LOG OUT")
                logout()
            }
        }
    ]

    function handleClick(event: DropDownButtonItemClickEvent): void {
        profileDropdownList.map(i => {
            if (i.text === event.item.text) {
                i.onClick() 
            } 
            return null
        })    
    }
   
    return (
        <ProfileDropdownStyles className='profileDropDown'>
            <DropDownButton
                buttonClass="profile-dropdown"
                items={profileDropdownList}
                onItemClick={handleClick}
                imageUrl={defaultProfile}
                iconClass='profile-image'
                tabIndex={0}
            />
        </ProfileDropdownStyles>
    )
})

export default ProfileDropdown
