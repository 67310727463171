import React from "react"
import Reports from "../components/Reports"

const ReportRoute: React.FC = props => {
    return (
        <Reports />
    )
}

export default ReportRoute
