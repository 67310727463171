import React from 'react'
import AlignDashboard from '../components/AlignDashboard'

const DashboardRoute: React.FC = props => {
    return (
        <AlignDashboard />
    )
}

export default DashboardRoute
