import React from 'react'
import { useField } from 'formik'
import styled from 'styled-components'

const AlignTextInputStyles = styled.div`
    .inline-error {
        display: flex;
        margin-left: 0.5rem;
        .body-text {
            margin-bottom: 0;
            line-height: unset;
        }
    }
`

interface AlignFormikTextInputInterface {
    label: string
    id?: string
    name?: string
    type?: string
    placeholder?: string
    materialIconName?:string
    iconAlignment?:'left'|'right'|undefined
    isReadOnly?:boolean
}

const AlignFormikTextInput: React.FC<AlignFormikTextInputInterface> = props => {
    const [field, meta] = useField(props as any);
    const {iconAlignment, materialIconName, isReadOnly, ...rest} = props
    const iconElementAlignment = !iconAlignment?'':iconAlignment === 'left'?'icon-input-left':'icon-input-right'
    const iconFieldAlignment = !iconAlignment?'':iconAlignment === 'left'?'icon-left':'icon-right'
    const readOnly = isReadOnly || false
    return (
        <AlignTextInputStyles className={`input-with-label ${materialIconName?'input-icon-wrapper':null}`}>
            <label htmlFor={props.id || props.name} className="input-title">{props.label}</label>
            <input className={`text-input input-text-field input medium ${iconFieldAlignment} w-input`} {...field} {...rest} readOnly={readOnly}/>
            {materialIconName && <div className={`material-icons ${iconElementAlignment}`} style={{top:`3.375rem`}}>{materialIconName}</div>}
            {meta.touched && meta.error ? (
                <div className="inline-error">
                    <div className='material-icons icon-left icon-list-item error'>error</div>
                    <div className='alert-content'>
                        <div className='body-text s'>{meta.error}</div>
                    </div>
                </div>
            ) : null}
        </AlignTextInputStyles>
    )
}

export default AlignFormikTextInput
