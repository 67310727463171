import React, { useEffect, useRef, useState } from 'react'
import AlignDialog, {DialogContext} from './components/AlignDialog'
import "@reach/dialog/styles.css"
import './css/App.css'
import styled from 'styled-components'
import {Outlet, useLocation, useNavigate} from 'react-router-dom'
import Navbar from './components/Navbar'
import {useUserManagerStore} from './models/UserManager'
import { observer } from 'mobx-react-lite'
import useAxios from './hooks/useAxios'
import { useStore as useRootStore } from './models/Root'
import { API_PATHS } from './api/ApiPaths'

const AppWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`

const App: React.FC = observer((props) => {
  let location = useLocation();
  const {data, authToken, error, requestUrl, fetch} = useAxios({method:'GET',initialValue:null})
  const {getAuthToken, setAuthToken}=useRootStore()
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [requestPath, setRequestPath] = useState('/')
  const isCheckingToken = useRef(false)
  const locationChanged = useRef(true)
  const navigate = useNavigate()
  const {login: fakeLogin} = useUserManagerStore()

  useEffect(() => {
    if(locationChanged.current){
      locationChanged.current = false
      if(getAuthToken() !== '' && !isCheckingToken.current){
        //console.log('check if our session is valid...', getAuthToken())
        isCheckingToken.current = true
        requestUrl.current = `${API_PATHS.IS_LOGGED_IN}`
        authToken.current = getAuthToken()
        fetch()
      }else if(getAuthToken() === '' && !isCheckingToken.current){
        navigate('/login',{replace:true})
      }
      setRequestPath(location.pathname)
    }
  }, [location.pathname, authToken, requestUrl, fetch, navigate, getAuthToken])

  useEffect(()=>{
    if(data){
      if(data === true && isCheckingToken.current){
        isCheckingToken.current = false
        //[TODO] since we still don't have real users and parts of our UI need roles then...
        fakeLogin('demouser@domain.com','!4PasswordShare')
        setIsAuthorized(true)
        //console.log('if current request is not equal then pass the current location path', requestPath, location.pathname)
        navigate(requestPath !== location.pathname?location.pathname:requestPath,{replace:true})
        locationChanged.current = true
      }
    }else if(data === false || error){
        setAuthToken('')
        setIsAuthorized(false)
        navigate('/login',{replace:true})
    }

  },[data, fakeLogin, navigate, requestPath, setAuthToken, setIsAuthorized, location.pathname, error])

  const [isOpen, setIsOpen] = useState(false)
  const [dialogContent, setDialogContent] = useState(<></>)
  const openDialog = (content?:JSX.Element)=>{
    if(content !== undefined){
      setDialogContent(content)
    }
    setIsOpen(true)
  }
  const closeDialog = ()=>{
    setIsOpen(false)
  }
  
  return (
    <DialogContext.Provider 
      value={{
        isOpen:isOpen,
        dialogContent:dialogContent,
        setDialogContent:setDialogContent,
        openDialog:openDialog,
        closeDialog:closeDialog
      }}
    >
      <AppWrapper>
        <Navbar
          style={{position:'relative', width:'100%'}}
        />
        <div className="App">
          { isAuthorized && <Outlet/> }
        </div>
        <AlignDialog 
          children={dialogContent}
        />
      </AppWrapper>
    </DialogContext.Provider>
  )
})

export default App
