import React from "react"
import {GridCellProps, GridRowProps, GridItemChangeEvent} from '@progress/kendo-react-grid'
import { MappingCellRender, RowRender } from "../components/MappingEditRenderers"
import { IMappingListItem } from "../models/AlignEditor"

const useMappingEditingUtils = (data:IMappingListItem[], changes:boolean, setData:React.Dispatch<React.SetStateAction<IMappingListItem[]>>, setChanges:React.Dispatch<React.SetStateAction<boolean>>)=>{
    const EDIT_FIELD = 'inEdit'

    const mappingCellRender: any = (
        td:  React.ReactElement<HTMLTableCellElement>, 
        props: GridCellProps) => 
        <MappingCellRender 
            originalProps={props} 
            td={td} 
            enterEdit={mappingEnterEdit} 
            editField={EDIT_FIELD}
        />
    
    const mappingRowRender: any = (
        tr: React.ReactElement<HTMLTableRowElement>, 
        props: GridRowProps) => 
        <RowRender 
            originalProps={props} 
            tr={tr} 
            exitEdit={mappingExitEdit} 
            editField={EDIT_FIELD}
        />

    const mappingEnterEdit = (dataItem: IMappingListItem, field: string) => {
        if(field === 'expanded' && !changes){
            //do the expansion thing instead ;-) 
        }else if (field === 'expanded' && changes){
            alert('If you collapse/expand you will lose changes, save them or cancel them and try again.')
        }else{
            const newData = data.map(item => ({
                ...item,
                [EDIT_FIELD]: item.list_item_id === dataItem.list_item_id ? field : undefined
            }));

            setData(newData);
        }
    }

    const mappingExitEdit = () => {
        if(!changes){
            //do the expand thing.
        }else{
            const newData = data.map(item => (
                { ...item, [EDIT_FIELD]: undefined }
            ));
    
            setData(newData);
        }
    }

    const mappingItemChange = (event: GridItemChangeEvent) => {
        let field = event.field || '';
        event.dataItem[field] = event.value;
        let newData = data.map(item => {
            let newDataItem:any = null
            if(item.list_item_id === event.dataItem.list_item_id){
                //we need to cast to any and re-cast to IObjective when done mutating.
                newDataItem = item
                newDataItem[field] = event.value;
            }
            return newDataItem?newDataItem as IMappingListItem:item
        })
        setData(newData);
        setChanges(true);
    }

    return {mappingCellRender, mappingRowRender, EDIT_FIELD, mappingItemChange}

}

export default useMappingEditingUtils
