import React, {useState, useEffect, useRef} from "react"
import AlignFlow from '../components/AlignFlow'
import styled from 'styled-components'
import { useStore } from '../models/AlignEditor'
import useAxios from '../hooks/useAxios'
import colorData from '../models/ColorData'
import {observer} from 'mobx-react-lite'
import { API_PATHS } from "../api/ApiPaths"
import colors from "../css/shared-styles"

const FlowMapWrapper = styled.div`
    flex: 1;
    display: flex;
    height: 98%;
    width: 98%;
    background-color: ${colors.white};
    justify-content: center;
    margin: auto;
`

const AlignFlowDemo: React.FC = observer(props => {
    const apiFetched = useRef(false)
    const {initProgramTreeNode, isStoreReady, initColors, colorsReady} = useStore()
    const [storeReady, setStoreReady] = useState(isStoreReady())
    const {data, error, fetch, requestUrl} = useAxios({method:"GET", initialValue:null})

    useEffect(()=>{
        if(!apiFetched.current){
            apiFetched.current = true
            //[TODO - hardcoded for now as we only have one client, assuming this will be dynamic with users login payload.]
            requestUrl.current = `${API_PATHS.PROGRAM_DATA}?program_uid=e0ba1881-5d68-408f-98ce-4fabd21c01c4`
            fetch()
        }
    })

    useEffect(()=>{
        if(data && !storeReady){
            initColors(colorData.colors)
            initProgramTreeNode([data])
            setStoreReady(isStoreReady())
        } else if (storeReady && !colorsReady){
            initColors(colorData.colors)
        }
    },[data, storeReady, colorsReady, initProgramTreeNode, isStoreReady, initColors])

    return (
        <>
            <FlowMapWrapper>
                {storeReady && colorsReady && <AlignFlow />}
                {error !== '' && <div>{String(error)}</div>}
            </FlowMapWrapper>
        </>
    )
})

export default AlignFlowDemo
