import React, { useEffect, useState } from "react"
import * as RadioGroup from "@radix-ui/react-radio-group"
import styled from "styled-components"

const AlignRadioGroupStyle = styled.div`

    .RadioGroupRoot {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    .RadioGroupItem {
        display: block;
        position: relative;
    }

    .RadioGroupIndicator {
        display: block;
        position: absolute;
        top: -1px;
        left: -1px;
    }

`

interface IRadioItem {
    id:string
    //Radix-UI requires all values to be strings even booleans so pass 'false' in quotes..
    value:string
    label:string
}

interface IAlignRadioGroup {
    radioGroupFieldName:string
    initialValue:string
    radioItems:IRadioItem[]
    handleChange:(fieldName:string, e:string)=>void
    readOnlyMode?:boolean
    radiosOrientation?:'horizontal'|'vertical'
    //string with the Id of the current focused element passed from the parent component used to add a class-name from the WebFlow styles to display focus status visually
    focusedElementId?:string|null
    zIndex?:number
}

const AlignRadioGroup: React.FC<IAlignRadioGroup> = (props)=>{
    const {radioGroupFieldName, initialValue, radioItems, handleChange, readOnlyMode, radiosOrientation, focusedElementId, zIndex} = props
    const [defaultValue, setDefaultValue] = useState(initialValue)

    const radioButtons:JSX.Element[] = radioItems.map((radioItem, index)=>{
        return (
            <div className={'radio-button-field w-radio'} key={index}>
                <RadioGroup.Item 
                    className='RadioGroupItem w-form-formradioinput w-form-formradioinput--inputType-custom radio-button w-radio-input' 
                    value={radioItem.value}
                    id={radioItem.id}
                >
                    <RadioGroup.Indicator className={`RadioGroupIndicator w-form-formradioinput w-form-formradioinput--inputType-custom radio-button w--redirected-checked ${focusedElementId === radioItem.id?'w--redirected-focus':null}`}/>
                </RadioGroup.Item>
                <label className="radio-button-label w-form-label" htmlFor={radioItem.id}>
                    {radioItem.label}
                </label>
            </div>
        )
    })

    useEffect(()=>{
        if(initialValue !== defaultValue){
            setDefaultValue(initialValue)
            //console.log('updating value?', initialValue)
        }
    },[initialValue, defaultValue, setDefaultValue])

    return (
        <AlignRadioGroupStyle className="align-radio-group" style={{zIndex:(zIndex !== undefined?zIndex:'initial'), cursor:readOnlyMode?'not-allowed':undefined}}>
            <RadioGroup.Root 
                className='RadioGroupRoot'
                style={{pointerEvents:readOnlyMode?'none':'auto'}}
                aria-label="Active"
                name={radioGroupFieldName}
                defaultValue={defaultValue}
                value={defaultValue}
                onValueChange={(e)=>{
                    handleChange(radioGroupFieldName, e)
                }}
                disabled={readOnlyMode}
                orientation={radiosOrientation}
            >
                {radioButtons}
            </RadioGroup.Root>
        </AlignRadioGroupStyle>
    )
}

export default AlignRadioGroup
