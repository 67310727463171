import React, {PropsWithChildren} from 'react';
import {useUserManagerStore} from '../models/UserManager'
import {observer} from 'mobx-react-lite'
import {toJS} from 'mobx'

interface IRequireRole {
    allowedRoles:string[]
    children: JSX.Element
}

const RequireRole:React.FC<IRequireRole & PropsWithChildren> = observer(props => {
    const {allowedRoles, children} = props
    const {getLoggedUser} = useUserManagerStore()
    const user = getLoggedUser()
    
    const isUserRoleValid = () => {
        let isAllowed = false
        let userRoles = toJS(user?.roles) || ['']
        allowedRoles.every((role)=>{
            if(userRoles.includes(role)){
                isAllowed = true
                return false
            }
            return true
        })
        //console.log(allowedRoles, userRoles, isAllowed, ' user role valid??')
        return isAllowed
    }
    
    return (
        isUserRoleValid() ? children
            : null
    )
})

export default RequireRole
