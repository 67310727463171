import { useState, useContext, useMemo, useRef } from 'react'
import {AxiosContext} from '../api/AxiosInstance'
import axios from 'axios'

export interface IUseAxios {
  method:string
  initialValue?:any
}

const useAxios = (props:IUseAxios) => {
  const {method, initialValue} = props
  const requestUrl = useRef('')
  const [responseData, setResponseData] = useState(initialValue);
  const payload = useRef<{}|null>(null)
  const [error, setError]= useState<any>('')
  const loading = useRef(true);
  const contextInstance = useContext(AxiosContext)
  const instance = useMemo(() => {
    return contextInstance || axios;
  }, [contextInstance])
  const controllerRef = useRef(new AbortController())
  const authToken = useRef('')
 
  //making this hook explicit as to when to call the action
  const fetch = async ()=> {
    loading.current = true
    try {
      const response = await instance.request({
        signal: controllerRef.current.signal,
        url:requestUrl.current,
        method:method,
        headers:{"Authorization":authToken.current},
        data:payload.current
      })
      if (response.status === 200) {
        loading.current = false
        setResponseData(response.data)
      }
    } catch ( theError) {
      loading.current = false
      setError(theError)
      throw theError
    } finally {
      //console.log('finally.')
    }
  }
  
  
  return { loading, data: responseData, error, payload, fetch, requestUrl, authToken };
}

export default useAxios
