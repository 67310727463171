import React from 'react';
//Navigate
import { useLocation, Outlet, Navigate } from 'react-router-dom'
import {useUserManagerStore} from '../models/UserManager'
import {observer} from 'mobx-react-lite'
import InvalidRoleAccess from './InvalidRoleAccess'
import {toJS} from 'mobx'

interface IRequireProtectedPath {
    allowedRoles:string[]
}

const RequireProtectedPath:React.FC<IRequireProtectedPath> = observer((props) => {
    const {allowedRoles} = props
    const {getLoggedUser, isLoggedIn} = useUserManagerStore()
    const user = getLoggedUser()
    const location = useLocation()
    const isUserRoleValid = () => {
        let isAllowed = false
        let userRoles = toJS(user?.roles) || ['']
        allowedRoles.every((role)=>{
            if(userRoles.includes(role)){
                isAllowed = true
                return false
            }
            return true
        })
        //console.log(allowedRoles, userRoles, isAllowed, ' user role valid??')
        return isAllowed
    }
    
    
    return (
        isLoggedIn
            ? isUserRoleValid() ? <Outlet />
            : <InvalidRoleAccess />
            : <Navigate to="/login" state={{ from: location }} replace />  
    )
})

export default RequireProtectedPath
