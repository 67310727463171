import React from "react"
import {GridItemChangeEvent} from '@progress/kendo-react-grid'
import { AlignListItemTypes } from "../components/AlignListGrid"

interface Props<T> {
    data: T[]
}

const useAlignListGridEditingUtilities =  (hookPagedData:AlignListItemTypes[],setData:React.Dispatch<React.SetStateAction<any>>)=>{

    const EDIT_FIELD = 'inEdit'
    const MOVE_FIELD = 'move'

    const enterEditMode = ()=>{
        const editModeData = hookPagedData.map(item => (
            { ...item, [EDIT_FIELD]: true }
        ))
        setData(editModeData)
    }

    const continueEditMode = <T extends AlignListItemTypes>({data}:Props<T>)=>{
        const editModeData = data.map(item => (
            { ...item, [EDIT_FIELD]: true }
        ))
        return editModeData
    }

    const exitEditMode = () => {
        const readOnlyData = hookPagedData.map(item => (
            { ...item, [EDIT_FIELD]: false, [MOVE_FIELD]:false }
        ))
        setData(readOnlyData)
    }

    const enterSaveMode = <T extends AlignListItemTypes>({data}:Props<T>)=>{
        const saveModeData = data.map(item => (
            { ...item, [EDIT_FIELD]: false, [MOVE_FIELD]:false}
        ))
        return saveModeData
    }

    const listItemChange = (event: GridItemChangeEvent) => {
        let field = event.field || ''
        if(field !== ''){
            //console.log('hookData before update ->','updating field: ',field, JSON.stringify(hookData))
            event.dataItem[field] = event.value
            let updatedHookData = hookPagedData.map(item => {
                let updatedDataItem:any = null
                if(item.id === event.dataItem.id){
                    //we need to cast to any and re-cast to IObjective when done mutating.
                    updatedDataItem = {...item}
                    updatedDataItem[field] = event.value
                }else{
                    if(field === 'move' && item[field]){
                        //clear all moves, only one row can be selected at a given time moving multiple can be tricky as they may not be adjacent.
                        updatedDataItem = {...item}
                        updatedDataItem[field] = false
                    }
                }

                return updatedDataItem?updatedDataItem as AlignListItemTypes:item
            })
            //console.log('updated hook data ->', JSON.stringify(updatedHookData))
            setData(updatedHookData);
        }
    }

    return { enterEditMode, exitEditMode, EDIT_FIELD, listItemChange, continueEditMode, enterSaveMode}

}

export default useAlignListGridEditingUtilities
