import React, {useEffect, useState, useRef} from 'react'
import {useStore} from '../models/AlignEditor'
import useAxios from '../hooks/useAxios'
import {observer} from 'mobx-react-lite'
import styled from 'styled-components'
import AlignListManagerView from './AlignListManagerView'

const ListManagerLoaderStyle = styled.div`
    overflow: hidden;
    height: 100%;
`

interface IListManagerLoader {
    listId: number
}

const ListManagerLoader: React.FC<IListManagerLoader> = observer(props => {
    const {listId} = props
    const {initListManager, listManagerReady, resetListManager} = useStore()
    const path = `/api:J6LhVvXz/demo/list/${listId}`
    const {data, error, fetch, requestUrl} = useAxios({method:"GET", initialValue:null})
    const [currentListId, setCurrentListId] = useState<number>(-1)
    const apiFetched = useRef(false)

    useEffect(()=>{
        if(apiFetched.current && listId){
            //console.log('list id changed right?', listId)
            apiFetched.current = false
        }
    },[listId])
     
    useEffect(()=>{
        if(!apiFetched.current){
            apiFetched.current = true
            resetListManager()
            if(listId !== -1){
                requestUrl.current = path
                fetch()
            }
        }
    },[listId, path, resetListManager, requestUrl, fetch])
 
    useEffect(()=>{
        if(apiFetched.current && data){
            //console.log('data?', data)
            setCurrentListId(initListManager(data))
        }
    },[data, initListManager, setCurrentListId])
         
    return (
         <ListManagerLoaderStyle className='list-manager-loader-style'>
             {apiFetched.current && listManagerReady && <AlignListManagerView listId={currentListId}/>}
             {error !== '' && <div>{JSON.stringify(error)}</div>}
         </ListManagerLoaderStyle>
     )
})

export default ListManagerLoader
