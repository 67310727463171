import React, { useState, useContext } from 'react'
import {useStore, ICurriculum, CurriculumInstance} from '../models/AlignEditor'
import '@progress/kendo-theme-default/dist/all.css'
import { 
    Grid, 
    GridColumn as Column,
    GridDetailRowProps,
    GridExpandChangeEvent,
    GridToolbar,
    GridCellProps
} from '@progress/kendo-react-grid'
import DetailModulesActivities from './DetailModulesActivities'
import styled from 'styled-components'
import useMappingEditingUtilities from '../hooks/useObjectivesEditingUtilities'
import { DrawerContext } from '../components/AlignDrawer'

const ToolbarStyling = styled.div`
    h6 {
        margin: 0;
    }
`

const DetailCourseModules:React.FC<GridDetailRowProps> = (props)=>{
    const {getCourseModules, toggleExpandCourseModule, getCourseMappings, saveCourseMappings, deleteCourseMapping, getCurrentProgramUid, getCurrentOrganizationId} = useStore() 
    const course = props.dataItem as CurriculumInstance
    const programUid = getCurrentProgramUid()
    const organizationId = getCurrentOrganizationId()
    const [data, setData] = useState<ICurriculum[]>(programUid?getCourseModules(programUid, course.uid):[])
    const [changes, setChanges] = useState<boolean>(false)
    const [dataMappings, setDataMappings] = useState(programUid?getCourseMappings(programUid, course.uid):[])
    const {mappingCellRender, mappingRowRender, EDIT_FIELD, mappingItemChange} = useMappingEditingUtilities(dataMappings, changes, setDataMappings, setChanges)
    const {setIsDrawerOpen, setUseListSelector, setOrganizationId} = useContext(DrawerContext)

    const saveMappingChanges = () => {
        setDataMappings(saveCourseMappings(programUid, course.uid, dataMappings))
        setChanges(false);
    }

    const cancelMappingChanges = () => {
        setDataMappings(programUid?getCourseMappings(programUid, course.uid):[]);
        setChanges(false);
    }

    const addNewMapping = () => {
        /*if(changes){
            alert('There are active changes, please save or cancel and try again.')
        }else{
            //createCourseObjective(programId,course.id)
            //setDataObjectives(programId?getCourseObjectives(programId, course.id):[]);
        }*/
        setUseListSelector && setUseListSelector(true)
        setOrganizationId && setOrganizationId(organizationId)
        setIsDrawerOpen(true)
    }

    const deleteMapping = (mappingUid:string)=>{
        deleteCourseMapping(programUid, course.uid, mappingUid)
        setDataMappings(programUid?getCourseMappings(programUid, course.uid):[]);
    }
    
    const EnhancedDetailModuleActivities: React.FC<GridDetailRowProps> = (props)=>{
        return <DetailModulesActivities programId={course.parent_uid?course.parent_uid:0} {...props}/>
    }

    const expandChange = (event:GridExpandChangeEvent)=>{
        const module = event.dataItem as CurriculumInstance
        // toggle module expand at the model
        if(programUid){
            toggleExpandCourseModule(programUid, course.uid, module.uid)
            //get the new snapshot and apply to this grid
            setData(getCourseModules(programUid,course.uid))
        }
    }

   
    return (
        <>
            <ToolbarStyling className='mappings-style'>
                <Grid
                    data={dataMappings}
                    onItemChange={mappingItemChange}
                    cellRender={mappingCellRender}
                    rowRender={mappingRowRender}
                    editField={EDIT_FIELD}
                >
                    <GridToolbar>
                        <h6>Course Mappings</h6>
                        <button 
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={addNewMapping}
                        >
                            Add Mapping
                        </button>
                        {changes && <button
                            title="Save"
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={saveMappingChanges}
                                >
                            Save Changes
                        </button>}
                        {changes && <button
                            title="Cancel Changes"
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={cancelMappingChanges}
                                >
                            Cancel Changes
                        </button>}
                    </GridToolbar>
                    <Column field="list_name" title="Mapping" editor='text' />
                    <Column field="list_item_description" title="Description" editor='text' />
                    <Column cell={(data:GridCellProps)=>{
                    return (<td><button onClick={()=>(window.confirm('Delete the mapping?')?deleteMapping(data.dataItem.list_item_id):false)}>Delete</button></td>)
                    }} title="Delete Mapping" width={130}/>
                </Grid>
            </ToolbarStyling>
            <ToolbarStyling>
                <Grid
                    data={data}
                    detail={EnhancedDetailModuleActivities}
                    expandField="expanded"
                    onExpandChange={expandChange}
                >
                    <GridToolbar>
                        <h6>Modules</h6>
                    </GridToolbar>
                    <Column field="display_name" title="Name" />
                </Grid>
            </ToolbarStyling>
        </>
    )
}

export default DetailCourseModules
