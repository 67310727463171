import React, { useState, useContext } from 'react'
import {useStore, CurriculumInstance} from '../models/AlignEditor'
import '@progress/kendo-theme-default/dist/all.css'
import { 
    Grid, 
    GridColumn as Column,
    GridDetailRowProps,
    GridToolbar,
    GridCellProps
} from '@progress/kendo-react-grid'
import styled from 'styled-components'
import useMappingEditingUtilities from '../hooks/useObjectivesEditingUtilities'
import { DrawerContext } from './AlignDrawer'

const ToolbarStyling = styled.div`
    h6 {
        margin: 0;
    }
`

const DetailActivity:React.FC<GridDetailRowProps> = (props)=>{
    const {getActivityMappings, saveActivityMappings, deleteActivityMapping, getCurrentProgramUid} = useStore() 
    const activity = props.dataItem as CurriculumInstance
    const programId = getCurrentProgramUid()
    const [changes, setChanges] = useState<boolean>(false)
    const [dataMappings, setDataMappings] = useState(programId?getActivityMappings(programId, activity.uid):[])
    const {mappingCellRender, mappingRowRender, EDIT_FIELD, mappingItemChange} = useMappingEditingUtilities(dataMappings, changes, setDataMappings, setChanges)
    const {setIsDrawerOpen} = useContext(DrawerContext)

    const saveMappingChanges = () => {
        setDataMappings(saveActivityMappings(programId, activity.uid, dataMappings))
        setChanges(false);
    }

    const cancelMappingChanges = () => {
        setDataMappings(programId?getActivityMappings(programId, activity.uid):[]);
        setChanges(false);
    }

    const addNewMapping = () => {
        /*if(changes){
            alert('There are active changes, please save or cancel and try again.')
        }else{
            //createCourseObjective(programId,course.id)
            //setDataObjectives(programId?getCourseObjectives(programId, course.id):[]);

        }*/
        setIsDrawerOpen(true)
    }

    const deleteMapping = (mappingUid:string)=>{
        deleteActivityMapping(programId, activity.uid, mappingUid)
        setDataMappings(programId?getActivityMappings(programId, activity.uid):[]);
    }
    
    return (
        <>
            <ToolbarStyling className='mappings-style'>
                <Grid
                    data={dataMappings}
                    onItemChange={mappingItemChange}
                    cellRender={mappingCellRender}
                    rowRender={mappingRowRender}
                    editField={EDIT_FIELD}
                >
                    <GridToolbar>
                        <h6>Activity Mappings</h6>
                        <button 
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={addNewMapping}
                        >
                            Add Mapping
                        </button>
                        {changes && <button
                            title="Save"
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={saveMappingChanges}
                                >
                            Save Changes
                        </button>}
                        {changes && <button
                            title="Cancel Changes"
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={cancelMappingChanges}
                                >
                            Cancel Changes
                        </button>}
                    </GridToolbar>
                    <Column field="list_name" title="Mapping" editor='text' />
                    <Column field="list_item_description" title="Description" editor='text' />
                    <Column cell={(data:GridCellProps)=>{
                    return (<td><button onClick={()=>(window.confirm('Delete the mapping?')?deleteMapping(data.dataItem.list_item_id):false)}>Delete</button></td>)
                    }} title="Delete Mapping" width={130}/>
                </Grid>
            </ToolbarStyling>
        </>
    )
}

export default DetailActivity
