import { types, Instance, SnapshotIn, applySnapshot, getSnapshot } from "mobx-state-tree"
import { createContext, useContext } from "react"
import { toJS } from "mobx"

export enum ROLES {
    ADMIN = 'admin',
    TEST = 'test',
    LEARNING_ARCHITECT = 'learningarchitect'
}

export enum ROLES_READABLE {
    admin = 'Administrator',
    test = 'Tester',
    learningarchitect = 'Learning Architect'
}

//creating ROLES Array object for demo purposes only, in reality these "Roles" will come from an End point anyway. 
export const rolesArray = [ROLES.ADMIN,ROLES.TEST,ROLES.LEARNING_ARCHITECT]

const User = types.model('User', 
    {
        id: types.string,
        email: types.string,
        firstName: types.string,
        lastName: types.string,
        password: types.string,
        organizationId: types.number,
        roles: types.optional(types.array(types.string),[]),
        move: types.optional(types.boolean, false)
    }
)

export type UserInstance = Instance<typeof User>
export interface IUser extends SnapshotIn<typeof User> {}

const UserManager = types.model('UsersSession',
    {
        users: types.optional(types.array(User),[]),
        loggedInUser: types.maybeNull(User),
        isLoggedIn: types.optional(types.boolean,false),
        loginError: types.optional(types.string,''),
        userManagerReady: types.optional(types.boolean,false)
    }
).actions(self => ({
    //this is probably not going to be used in prod but rather we will be calling a server
    //to get a list of users, but since we still don't have a back end with that we
    //are creating this mock model service.
    initUsers(){
        if(!self.userManagerReady){
            applySnapshot(self.users, [
                    {
                        id:'3ff99717-c1cd-4d87-94a4-d8e6a28672d6',
                        email:'demouser@domain.com',
                        firstName:'Demo',
                        lastName:'User',
                        password:'!4PasswordShare',
                        organizationId:1,
                        roles:[ROLES.LEARNING_ARCHITECT, ROLES.ADMIN, ROLES.TEST]
                    },
                    {
                        id:'3a84889b-6433-4a2b-85a5-4b4348972fc4',
                        email:'summer@idesign.com',
                        firstName:'Summer',
                        lastName:'Sun',
                        password:'rise',
                        organizationId:1,
                        roles:[ROLES.TEST]
                    },
                    {
                        id:'d5789e19-5a7f-45bf-b2z9-d0f7fdd8e0ab',
                        email:'jobs@idesign.com',
                        firstName:'Steve',
                        lastName:'Jobs',
                        password:'otherworld',
                        organizationId:1,
                        roles:[ROLES.ADMIN]
                    },
                    {
                        id:'3ff99717-c1cd-4d87-94a4-d8e6a78972d6',
                        email:'larry@idesign.com',
                        firstName:'Larry',
                        lastName:'Mcintosh',
                        password:'iamnotsteve',
                        organizationId:1,
                        roles:[ROLES.LEARNING_ARCHITECT]
                    },
                    {
                        id:'3a84889b-6433-4a2b-85a5-4b4355672fc4',
                        email:'collins@idesign.com',
                        firstName:'Mike',
                        lastName:'Collins',
                        password:'justWork',
                        organizationId:1,
                        roles:[ROLES.TEST]
                    },
                    {
                        id:'d5789e19-5a7f-45bf-b2z9-d0f7fbb3e0ab',
                        email:'norton@idesign.com',
                        firstName:'Peter',
                        lastName:'Norton',
                        password:'antivirus',
                        organizationId:1,
                        roles:[ROLES.ADMIN]
                    },
                    {
                        id:'3ff99717-c1cd-4d87-94a4-d8e6a24672d6',
                        email:'peter@idesign.com',
                        firstName:'Peter',
                        lastName:'Jackson',
                        password:'OnePassToRuleThemAll',
                        organizationId:1,
                        roles:[ROLES.LEARNING_ARCHITECT]
                    },
                    {
                        id:'3a84889b-6433-4a2b-85a5-4b4344472fc4',
                        email:'pedro@idesign.com',
                        firstName:'Pedro',
                        lastName:'Lopes',
                        password:'iAmPedro',
                        organizationId:1,
                        roles:[ROLES.TEST]
                    },
                    {
                        id:'d5789e19-5a7f-45bf-b2z9-d0f7fcc3e0ab',
                        email:'godzilla@idesign.com',
                        firstName:'Reptiles',
                        lastName:'Rule',
                        password:'godzilla',
                        organizationId:1,
                        roles:[ROLES.ADMIN]
                    },
                    {
                        id:'3ff99717-c1cd-4d87-94a4-d8e6a11672d6',
                        email:'daniel@idesign.com',
                        firstName:'Daniel',
                        lastName:'Taylor',
                        password:'taylor1234',
                        organizationId:1,
                        roles:[ROLES.LEARNING_ARCHITECT]
                    },
                    {
                        id:'3a84889b-6433-4a2b-85a5-4b4337072fc4',
                        email:'test@idesign.com',
                        firstName:'Test',
                        lastName:'User',
                        password:'tesT123',
                        organizationId:1,
                        roles:[ROLES.TEST]
                    },
                    {
                        id:'d5789e19-5a7f-45bf-b2b5-d0f7fcc3e0ab',
                        email:'admin@idesign.com',
                        firstName:'Admin',
                        lastName:'Admin',
                        password:'AdmiN123',
                        organizationId:1,
                        roles:[ROLES.ADMIN]
                    },
                    {
                        id:'ef98e986-b411-4846-897f-77028a354ce8',
                        email:'smith@idesign.com',
                        firstName:'John',
                        lastName:'Smith',
                        password:'smith123',
                        organizationId:2,
                        roles:[ROLES.LEARNING_ARCHITECT]
                    },
                    {
                        id:'b49bf6c9-0aad-4f23-bff1-f530017265d3',
                        email:'reagan@idesign.com',
                        firstName:'Reagan',
                        lastName:'Thomson',
                        password:'blur1234',
                        organizationId:2,
                        roles:[ROLES.TEST]
                    },
                    {
                        id:'eee6cf2e-d62f-4ab0-9c1c-0a44af122ea2',
                        email:'cando@idesign.com',
                        firstName:'Can',
                        lastName:'DoiTAll',
                        password:'justdoit1234',
                        organizationId:2,
                        roles:[ROLES.ADMIN, ROLES.LEARNING_ARCHITECT, ROLES.TEST]
                    }
                ]
            )
            self.userManagerReady = true
        }
    },
    login(userEmail:string, userPassword:string){
        self.users.some((user)=>{
            if(user.email === userEmail && user.password === userPassword){
                self.loggedInUser = toJS(user)
                self.isLoggedIn = true
                return true
            }
            return false
        })
        //console.log('loggedIn?', self.loggedInUser, !self.loggedInUser)
        if(!self.loggedInUser){
            console.log('is this happening?')
            self.loginError = 'User or Pass error.'
            self.isLoggedIn = false
            return false
        }
        return {status:true,token:'myCrazyToken1234'}
    },
    logout(){
        self.loggedInUser = null
        self.isLoggedIn = false
        self.loginError = ''
    }
})).views(self => ({
    getLoggedUser(){
        return self.loggedInUser
    },
    getLogInError(){
        return self.loginError
    },
    getUsersByOrganizationId(organizationId:number){
        let currentSnapshot = getSnapshot(self.users)
        return currentSnapshot.filter(user => user.organizationId === organizationId)
    },
    getIsLoggedIn(){
        return self.isLoggedIn
    }
}))

export type UserManagerInstance = Instance<typeof UserManager>
export interface IUserManager extends SnapshotIn<typeof UserManager> {}
export const UserManagerStore = UserManager.create()

export type UserManagerStoreTypeInstance = Instance<typeof UserManagerStore>

const UserManagerStoreContext = createContext<UserManagerStoreTypeInstance>({} as UserManagerStoreTypeInstance)

export const Provider = UserManagerStoreContext.Provider

/**
 * @description: A mobx state tree model instance to manage the User Manger Component state, all edits made at the UI of the user manager
 * are kept here and then if desired will be "posted" to the corresponding back end point. Additionally there are a couple Interfaces (User and UsersSession)
 * that are derived form the models and used throughout the application thus any changes here will be reflected in all components using the instance
 * which is pretty handy and removes interface duplication. 
 * 
 * An instance "store" is passed to the main application via context and its provider, so it will be available to any component using the context mechanisms exposed
 * by React.  
 */
export const useUserManagerStore = () => {
    const store = useContext(UserManagerStoreContext)
    //populate with sample users for dev purposes only
    store.initUsers()
    if (store === null) {
        throw new Error('Store cannot be null, please add a context provider')
    }
    return store as UserManagerStoreTypeInstance
}

export type MapStore<T> = (store: UserManagerStoreTypeInstance) => T

export const useInject = <T>(mapStore: MapStore<T>) => {
    const store = useUserManagerStore()
    return mapStore(store)
}
