import React from "react"
import {GridCellProps, GridRowProps, GridItemChangeEvent} from '@progress/kendo-react-grid'
import { CellRender, RowRender } from "../components/CustomEditRenderers"
import { ICurriculum } from "../models/AlignEditor"

const useCurriculumTreeNodeEditingUtils = (data:ICurriculum[], changes:boolean, setData:React.Dispatch<React.SetStateAction<ICurriculum[]>>, setChanges:React.Dispatch<React.SetStateAction<boolean>>)=>{
    const EDIT_FIELD = 'inEdit'

    const customCellRender: any = (
        td:  React.ReactElement<HTMLTableCellElement>, 
        props: GridCellProps) => 
        <CellRender 
            originalProps={props} 
            td={td} 
            enterEdit={enterEdit} 
            editField={EDIT_FIELD}
        />
    
    const customRowRender: any = (
        tr: React.ReactElement<HTMLTableRowElement>, 
        props: GridRowProps) => 
        <RowRender 
            originalProps={props} 
            tr={tr} 
            exitEdit={exitEdit} 
            editField={EDIT_FIELD}
        />

    const enterEdit = (dataItem: ICurriculum, field: string) => {
        if(field === 'expanded' && !changes){
            //do the expansion thing instead ;-) 
        }else if (field === 'expanded' && changes){
            alert('If you collapse/expand you will lose changes, save them or cancel them and try again.')
        }else{
            const newData = data.map(item => ({
                ...item,
                [EDIT_FIELD]: item.uid === dataItem.uid ? field : undefined
            }));

            setData(newData);
        }
    }

    const exitEdit = () => {
        if(!changes){
            //do the expand thing.
        }else{
            const newData = data.map(item => (
                { ...item, [EDIT_FIELD]: undefined }
            ));
    
            setData(newData);
        }
    }

    const itemChange = (event: GridItemChangeEvent) => {
        let field = event.field || '';
        event.dataItem[field] = event.value;
        let newData = data.map(item => {
            let newDataItem:any = null
            if(item.uid === event.dataItem.uid){
                //we need to cast to any and re-cast to ICurriculum when done mutating.
                newDataItem = item
                newDataItem[field] = event.value;
            }
            return newDataItem?newDataItem as ICurriculum:item
        })
        setData(newData);
        setChanges(true);
    }

    return {customCellRender, customRowRender, EDIT_FIELD, itemChange}

}

export default useCurriculumTreeNodeEditingUtils
