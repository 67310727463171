import React, { useEffect, useRef, useState, useCallback } from 'react'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import {IUser} from '../models/UserManager'
import styled from 'styled-components'
import {GridPageChangeEvent, GridColumn as Column} from '@progress/kendo-react-grid'
import AlignListGrid, {IPageState, pageDefault } from './AlignListGrid'
import {CustomInputTextCell, CustomMultiCheckBoxInputCell} from './AlignListGridCustomInputCells'
import {useUserManagerStore} from '../models/UserManager'
import useAlignListGridEditingUtilities from '../hooks/useAlignListGridEditingUtilities'
import AlignEditModeButton from './AlignEditModeButton'
import colors from '../css/shared-styles'




interface IAdmin {
    organizationId:number
}

const CustomGridStyles = styled.div `
    padding: 0.5rem;
    border-radius: 3px;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    div.k-widget.k-grid {
        overflow: hidden;
        height: 100%;
    }
    form.k-form {
        display: flex;
        .k-form-fieldset {
            width: 100%;
        }
    }
    .main-form {
        padding-bottom: 1rem;
    }
    .input-text-field {
        width: 100%;
    }

    .Label {
        color: black;
        font-size: 15px;
        line-height: 1;
        user-select: none;
        padding-left: .4rem;
    }

    .k-pager-numbers-wrap {
        a {
            color: ${colors.linkBlue};
            &.k-link.k-selected, :hover {
                color: ${colors.linkBlue};
                background-color: ${colors.selectedBlue};
                border-radius: 6px;
            }
        } 
    }
`

interface IAdminHiddenPagedGridData {
    prefixArray:IUser[]
    suffixArray:IUser[]
}

const AdminView:React.FC<IAdmin> = (props)=>{
    const {organizationId} = props
    const dataSet = useRef(0)
    const displayReady = useRef(false)
    const {getUsersByOrganizationId} = useUserManagerStore()
    const mock = new MockAdapter(axios)
    const [usersData, setUsersData] = useState<IUser[]>(getUsersByOrganizationId(organizationId))
    const [page, setPage] = useState<IPageState>(pageDefault)
    const [pagedGridData, setPagedGridData] = useState<IUser[]>(usersData.slice(page.skip, page.take + page.skip))
    const [currentPage,setCurrentPage] = useState<IPageState>(pageDefault)
    const [readOnlyMode, setReadOnlyMode] = useState(true)
    const {enterEditMode, exitEditMode, EDIT_FIELD, listItemChange, continueEditMode, enterSaveMode} = useAlignListGridEditingUtilities(pagedGridData,setPagedGridData)
    
    const getNonVisiblePagedGridData = useCallback(():IAdminHiddenPagedGridData =>{
        return {
            prefixArray:page.skip!==0?usersData.slice(0, page.skip):[],
            suffixArray:usersData.slice(page.take + page.skip,usersData.length+1)
        }
    },[page.skip, page.take, usersData])
    const [nonVisiblePagedGridData,setNonVisiblePagedGridData] = useState<IAdminHiddenPagedGridData>(getNonVisiblePagedGridData())
    const shouldRevertPagedGridData = useRef(false)
    const currentGridDataLength = useRef(usersData.length)

    //mock calls to populate component data - [Todo - remove when we have real end points]
    mock.onGet('/organizations/1/users/').reply(200, getUsersByOrganizationId(1))
    mock.onGet('/organizations/2/users/').reply(200, getUsersByOrganizationId(2))


    useEffect(()=>{
        if(dataSet.current !== organizationId){
            //displayReady.current = false
            dataSet.current = organizationId
            displayReady.current = false
            axios.get(`/organizations/${organizationId}/users/`).then((response)=>{
                //console.log('is this working?', response.data)
                setUsersData(response.data)
            }).catch(()=>{
                dataSet.current = 0
            })
        }
    },[organizationId])

    //Paging actions start
    const updatePagedGridData = useCallback(()=>{
        if(readOnlyMode){
            setPagedGridData(usersData.slice(page.skip, page.take + page.skip))
        }else if(!readOnlyMode){
            setPagedGridData(continueEditMode({data:usersData.slice(page.skip, page.take + page.skip)}))
        }
        setNonVisiblePagedGridData(getNonVisiblePagedGridData())
    },[readOnlyMode, usersData, setPagedGridData, page.skip, page.take, continueEditMode, getNonVisiblePagedGridData])

    useEffect(()=>{
        if(page.skip !== currentPage.skip || page.take !== currentPage.take){
            setCurrentPage(page)
            updatePagedGridData()
        }
    },[page, page.skip, page.take, currentPage, currentPage.skip, currentPage.take, setCurrentPage, updatePagedGridData])

    useEffect(()=>{
        //UseEffect helping with deletion operations of items.
        if(readOnlyMode && shouldRevertPagedGridData.current){
            //console.log('reverting to last snapshot.')
            shouldRevertPagedGridData.current = false
            setPagedGridData(usersData.slice(page.skip, page.take + page.skip))
        }else if (!readOnlyMode && currentGridDataLength.current !== usersData.length){
            //data length changed so update our grid data ref
            currentGridDataLength.current = usersData.length
            updatePagedGridData()
        }
    },[readOnlyMode, usersData, page.skip, page.take, updatePagedGridData])

    const pageChange = (event: GridPageChangeEvent) => {
        if(!readOnlyMode){
            let updatedUsersData = nonVisiblePagedGridData.prefixArray.concat(pagedGridData,nonVisiblePagedGridData.suffixArray)
            setUsersData(updatedUsersData)
        }
        setPage(event.page)
    }
    //Paging actions end

    const cancelChanges = ()=>{
        console.log('we will cancel all user changes.')
        exitEditMode()
        shouldRevertPagedGridData.current = true
        setReadOnlyMode(true)
    }

    const saveChanges = ()=>{
        console.log('we will save all user changes.')
        setUsersData(enterSaveMode({data:nonVisiblePagedGridData.prefixArray.concat(pagedGridData,nonVisiblePagedGridData.suffixArray)}))
        exitEditMode()
        setReadOnlyMode(true)
    }

    useEffect(()=>{
        if(!displayReady.current){
            displayReady.current = true
            setPagedGridData(usersData.slice(page.skip, page.take + page.skip))
        }
    },[usersData, setPagedGridData, page.skip, page.take])
    

    return (
        <CustomGridStyles>
            <AlignEditModeButton 
                readOnlyMode={readOnlyMode}
                setReadOnlyMode={setReadOnlyMode}
                enterEditMode={enterEditMode}
                cancelChanges={cancelChanges}
                saveChanges={saveChanges}
                onClickCallback={()=>{
                    //[Todo: init user manager instance here]
                    //listManagerInstance().setLastSnapshot()
                }}
                alignRight={true}
            />
            <h5>Manage Users</h5>
            {<AlignListGrid
                pagedGridData={pagedGridData}
                listItemChange={listItemChange}
                editField={EDIT_FIELD}
                page={page}
                gridData={usersData}
                setGridData={setUsersData}
                pageChange={pageChange}
                setPagedGridData={setPagedGridData}
                readOnlyMode={readOnlyMode}
                deleteItemConfirmDialogTitle={'Remove this user?'}
                gridDataType={'AdminUserItems'}
            >
                <Column 
                    title="First Name"
                    field="firstName"
                    cell={CustomInputTextCell}
                />
                <Column 
                    title="Last Name"
                    field="lastName"
                    cell={CustomInputTextCell}
                />
                <Column 
                    title="Email"
                    field="email"
                    cell={CustomInputTextCell}
                />
                <Column 
                    title="Roles"
                    field="roles"
                    cell={CustomMultiCheckBoxInputCell}
                />
            </AlignListGrid>}
        </CustomGridStyles>
    )
}

export default AdminView
        
