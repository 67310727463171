import React from 'react'
import { useField } from 'formik'

interface IAlignFormikCheckbox {
    label?: string
    id?: string
    name: string
    children: JSX.Element | JSX.Element[] | string
}

const AlignFormikCheckbox: React.FC<IAlignFormikCheckbox> = props => {
    const [field, meta] = useField({ ...props, type: "checkbox" })
    const {id, name} = props

    return (
      <>
        <label>
          <input {...field} id={id} name={name} type="checkbox" />
          {props.children}
        </label>
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </>
    )
}

export default AlignFormikCheckbox
