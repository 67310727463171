import React, {useEffect, useRef, useState} from 'react'
import AlignCurriculumDataGrid from '../components/AlignCurriculumDataGrid'
import {useStore} from '../models/AlignEditor'
import useAxios from '../hooks/useAxios'
import AlignDrawer from '../components/AlignDrawer'
import { API_PATHS } from '../api/ApiPaths'


const DataGridDemo: React.FC = props => {
    const {initProgramTreeNode, isStoreReady} = useStore()
    const apiFetched = useRef(false)
    //[TODO - hardcoded 1 program for now, we assume that program uid will come from the logged in user payload somehow. ]
    const {data, error, requestUrl, fetch} = useAxios({method:"GET", initialValue:null})

    const [storeReady, setStoreReady] = useState(isStoreReady())
    
    useEffect(()=>{
        if(!apiFetched.current){
            apiFetched.current = true
            requestUrl.current = `${API_PATHS.PROGRAM_DATA}?program_uid=e0ba1881-5d68-408f-98ce-4fabd21c01c4`
            fetch()
        }
    })
    
    useEffect(()=>{
        if(data && !storeReady){
            initProgramTreeNode([data])
            setStoreReady(isStoreReady())
        }
    },[data, initProgramTreeNode, storeReady, isStoreReady])

    return (
        <AlignDrawer>
            {storeReady && <AlignCurriculumDataGrid />}
            {!setStoreReady && error ==='' && <div>Loading...</div>}
            {error !== '' && <div>{String(error)}</div>}
        </AlignDrawer>
    )
}

export default DataGridDemo
