import React, { useEffect, useState, useRef, useCallback } from 'react'
import {useStore } from '../models/AlignEditor'
import {IListManagerList, IListManagerListItem } from '../models/ListManager'
import '@progress/kendo-theme-default/dist/all.css'
import {GridPageChangeEvent, GridColumn as Column} from '@progress/kendo-react-grid'
import {useFormikContext, Formik, Form, Field, ErrorMessage} from 'formik';
import styled from 'styled-components'
import {getReadableDate} from '../utilities/DateFormat'
import AlignRadioGroup from './AlignRadioGroup'
import AlignDropDown, {ISelectItem} from './AlignDropDown'
import useAxios from '../hooks/useAxios'
import { API_PATHS } from '../api/ApiPaths'
import useAlignListGridEditingUtilities from '../hooks/useAlignListGridEditingUtilities'
import AlignListGrid, {pageDefault, IPageState} from './AlignListGrid'
import colors from '../css/shared-styles'
import {CustomInputTextCell, CustomCheckBoxCell} from './AlignListGridCustomInputCells'
import AlignEditModeButton from './AlignEditModeButton'


const CustomGridStyles = styled.div`
    border: 1px solid ${colors.lightGrey};
    padding: 0.5rem;
    border-radius: 3px;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    div.k-widget.k-grid {
        overflow: hidden;
        height: 100%;
    }
    form.k-form {
        display: flex;
        .k-form-fieldset {
            width: 100%;
        }
    }
    .main-form {
        padding-bottom: 1rem;
    }
    .input-text-field {
        width: 100%;
    }

    .Label {
        color: black;
        font-size: 15px;
        line-height: 1;
        user-select: none;
        padding-left: .4rem;
    }

    .k-pager-numbers-wrap {
        a {
            color: ${colors.linkBlue};
            &.k-link.k-selected, :hover {
                color: ${colors.linkBlue};
                background-color: ${colors.selectedBlue};
                border-radius: 6px;
            }
        } 
    }
`


interface IAlignListManagerDataGrid {
    listId:number
}

interface IHiddenPagedGridData {
    prefixArray:IListManagerListItem[]
    suffixArray:IListManagerListItem[]
}

const AlignListManagerView: React.FC<IAlignListManagerDataGrid> = (props) => {
    const {listManagerInstance} = useStore()
    const [page, setPage] = useState<IPageState>(pageDefault)
    const [currentPage,setCurrentPage] = useState<IPageState>(pageDefault)
    const [listData, setListData] = useState<IListManagerList[]>(listManagerInstance().getCurrentSnapshot())
    const [gridData, setGridData] = useState<IListManagerListItem[]>(listManagerInstance().getListItems())
    const [pagedGridData, setPagedGridData] = useState<IListManagerListItem[]>(gridData.slice(page.skip, page.take + page.skip))
    const getNonVisiblePagedGridData = useCallback(():IHiddenPagedGridData =>{
        return {
            prefixArray:page.skip!==0?gridData.slice(0, page.skip):[],
            suffixArray:gridData.slice(page.take + page.skip,gridData.length+1)
        }
    },[page.skip, page.take, gridData])
    const [nonVisiblePagedGridData,setNonVisiblePagedGridData] = useState<IHiddenPagedGridData>(getNonVisiblePagedGridData())
    // eslint-disable-next-line
    const {data:list_types, error:dataError, requestUrl, fetch} = useAxios({method:"GET", initialValue:null})
    const [readOnlyMode, setReadOnlyMode] = useState(true)
    const {enterEditMode, exitEditMode, EDIT_FIELD, listItemChange, continueEditMode, enterSaveMode} = useAlignListGridEditingUtilities(pagedGridData,setPagedGridData)
    const resetFromNow = useRef(false)
    const shouldRevertPagedGridData = useRef(false)
    const currentGridDataLength = useRef(gridData.length)
    const [focusedElement, setFocusedElement] = useState<string|null>(null)
    const [availableListTypes, setAvailableListTypes] = useState<ISelectItem[]|null>(null)
    const fetchListTypes = useRef(true)

    useEffect(()=>{
        if(document.activeElement){
            setFocusedElement(document.activeElement.id?document.activeElement.id:null)
            //console.log(focusedElement,'focused Element changed.')
        }
        // eslint-disable-next-line
    },[focusedElement, document.activeElement])

    useEffect(()=>{
        if(fetchListTypes.current){
            fetchListTypes.current = false
            requestUrl.current = `${API_PATHS.LIST_TYPES}/${listData[0].organization_id}`
            fetch()
        }
    },[fetch, listData, requestUrl])

    useEffect(()=>{
        //list_types drop down menu items
        if(!fetchListTypes.current && list_types !== null && list_types.length){
            let tempListTypes:ISelectItem[] = []
            list_types.forEach((type:string)=>{
                tempListTypes.push({label:type, value:type})
            })
            setAvailableListTypes(tempListTypes)
        }
    },[list_types])


    const updatePagedGridData = useCallback(()=>{
        if(readOnlyMode){
            setPagedGridData(gridData.slice(page.skip, page.take + page.skip))
        }else if(!readOnlyMode){
            setPagedGridData(continueEditMode({data:gridData.slice(page.skip, page.take + page.skip)}))
        }
        setNonVisiblePagedGridData(getNonVisiblePagedGridData())
    },[readOnlyMode, gridData, setPagedGridData, page.skip, page.take, continueEditMode, getNonVisiblePagedGridData])

    useEffect(()=>{
        if(page.skip !== currentPage.skip || page.take !== currentPage.take){
            setCurrentPage(page)
            updatePagedGridData()
        }

    },[page, page.skip, page.take, currentPage, currentPage.skip, currentPage.take, setCurrentPage, updatePagedGridData])

    useEffect(()=>{
        //console.log('grid data changed right?', gridData.length)
        if(readOnlyMode && shouldRevertPagedGridData.current){
            console.log('reverting to last snapshot.')
            shouldRevertPagedGridData.current = false
            setPagedGridData(gridData.slice(page.skip, page.take + page.skip))
        }else if (!readOnlyMode && currentGridDataLength.current !== gridData.length){
            console.log('since gridData changed, updating pagedGridData is next.')
            currentGridDataLength.current = gridData.length
            updatePagedGridData()
        }
    },[readOnlyMode, gridData, page.skip, page.take, updatePagedGridData])

    const pageChange = (event: GridPageChangeEvent) => {
        /*if we are in edit mode, we need to assume some changes were made and update the original 
        grid data to preserve them across paging actions, if no changes were made but still in edit
         mode we can still do the same mutation to keep things consistent.*/
        if(!readOnlyMode){
            let updatedGridData = nonVisiblePagedGridData.prefixArray.concat(pagedGridData,nonVisiblePagedGridData.suffixArray)
            setGridData(updatedGridData)
        }
        setPage(event.page)
    }

    const clearFocus = ()=>{
        setFocusedElement(null)
    }

    const cancelChanges = ()=>{
        console.log('we will cancel all changes.')
        exitEditMode()
        setListData(listManagerInstance().revertChanges())
        setGridData(listManagerInstance().getListItems())
        shouldRevertPagedGridData.current = true
        setReadOnlyMode(true)
        resetFromNow.current = true
    }

    const saveChanges = ()=>{
        console.log('we will save all changes.')
        setGridData(enterSaveMode({data:nonVisiblePagedGridData.prefixArray.concat(pagedGridData,nonVisiblePagedGridData.suffixArray)}))
        exitEditMode()
        setReadOnlyMode(true)
    }

    const ResetFormHook = () => {
        const {resetForm } = useFormikContext();
        useEffect(() => {
            if(resetFromNow.current){
                resetFromNow.current = false
                resetForm()
            }
        }, [resetForm])
        return null
    }

    const handleListHeaderChange = useCallback(
        //Handles the upper part of the list, not the fields on the grid.
        (fieldName:string, value:string) => {
            if(value !=='' && fieldName !== ''){
                listManagerInstance().updateValue(fieldName, value === 'false'?false:value === 'true'?true:value)
                setListData(listManagerInstance().getCurrentSnapshot())
            }
        },[listManagerInstance]
      )

    return (
        <CustomGridStyles className='custom-grid-styles' onMouseDownCapture={(e)=>{
            e.stopPropagation()
            clearFocus()
        }}>
            <AlignEditModeButton 
                readOnlyMode={readOnlyMode}
                setReadOnlyMode={setReadOnlyMode}
                enterEditMode={enterEditMode}
                cancelChanges={cancelChanges}
                saveChanges={saveChanges}
                onClickCallback={()=>{
                    listManagerInstance().setLastSnapshot()
                }}
                alignRight={true}
            />
            <Formik
                initialValues={{
                    name: listData[0].name
                }}
                validate={values => {
                    const errors = {name:''};
                    if (values.name.length === 0) {
                        errors.name = 'List name cannot be empty.';
                    }
                    return errors;
                }}
                onSubmit={() => {}}
                >
                {props => (
                    <Form className='main-form'>
                        <div className="mb-3 input-with-label">
                            <label htmlFor="name" className='input-title'>List name</label>
                            <Field name="name" type="text" readOnly={readOnlyMode} className="input-text-field input medium w-input" />
                            <ErrorMessage name="name" />
                        </div>
                        <div className='details-wrap w-layout-grid l-g-4-col'>
                            <div>
                                <label className='input-title'>List type:</label>
                                {availableListTypes && <AlignDropDown 
                                    fieldName='list_type'
                                    value={listData[0].list_type}
                                    handleValueChange={handleListHeaderChange}
                                    readOnlyMode={readOnlyMode}
                                    selectItems={availableListTypes}
                                />}
                                {!availableListTypes && <span>loading...</span>}
                            </div>
                            <div>
                                <label className='input-title'>Active:</label>
                                <AlignRadioGroup
                                    radioGroupFieldName='is_active'
                                    initialValue={listData[0].is_active?'true':'false'}
                                    handleChange={handleListHeaderChange}
                                    focusedElementId={focusedElement}
                                    readOnlyMode={readOnlyMode}
                                    radioItems={[
                                        {id:'radio1', value:'true',label:'Yes'},
                                        {id:'radio2', value:'false', label:'No'}
                                    ]}
                                    zIndex={10}
                                />
                            </div>
                            <div>
                                <label className='input-title'>Created on:</label>
                                <p className='body-text s'>
                                    {getReadableDate(listData[0].created_at)}
                                </p>
                            </div>
                            <div>
                                <label className='input-title'>Organization ID:</label>
                                <p className='body-text s'>
                                    {listData[0].organization_id}
                                </p>
                            </div>
                        </div>
                            <ResetFormHook />
                    </Form>
                )}
            </Formik>
            <AlignListGrid 
                pagedGridData={pagedGridData}
                listItemChange={listItemChange}
                editField={EDIT_FIELD}
                page={page}
                gridData={gridData}
                setGridData={setGridData}
                pageChange={pageChange}
                setPagedGridData={setPagedGridData}
                readOnlyMode={readOnlyMode}
                gridDataType={'ListManagerItems'}
            >
                <Column 
                    title="Description"
                    field="description"
                    cell={CustomInputTextCell}
                />
                <Column
                    title="List Header"
                    field="is_heading"
                    cell={CustomCheckBoxCell}
                    width="100px"
                />
                <Column
                    title="Mappable"
                    field="is_mappable"
                    cell={CustomCheckBoxCell}
                    width="100px"
                />
            </AlignListGrid>
        </CustomGridStyles>
    )
}

export default AlignListManagerView
