import React, { SyntheticEvent } from 'react'
import styled from 'styled-components'

interface IAlignButton {
    alignRight?:boolean
    materialIconName?:string
    label?:string
    onClick?:((e?:any)=>void)
    style?:React.CSSProperties
    type?:'button'|'submit'|'reset'|undefined
}

const ButtonWrap = styled.button<IAlignButton>`
    display: flex;
    width: fit-content;
    ${props => props.alignRight?`margin-left: auto;`:''}
    div.material-icons {
        padding-left: .3rem;
        font-size: 1.25rem;
    }
    div.button-content-wrapper {
        width: 100%;
    }
`

/**
 * @description A button that is styled using the iDesign webflow styles, use this whenever you need to use a button which is also accessible.
 * @param props.alignRight:boolean optional Used to make the whole button align right, may need to have a flex parent
 * @param props.materialIconName:string optional Pass the name of a material design icon here and it will be used and displayed within the button.
 * @param props.label:string optional The button label 
 * @param props.onClick:(e:Synthetic event)=>void optional handler triggered then button is clicked
 * @param props.style:React.CSSProperties optional
 * @param props.type:string optional either 'button'|'submit'|'reset'| undefined defining the button type.
 * @returns styled button instance component
 */

const AlignButton: React.FC<IAlignButton> = props => {
    const {alignRight, materialIconName, label, onClick, style, type} = props
    
    return (
        <ButtonWrap 
            onClick={(e:SyntheticEvent)=>{onClick && onClick(e)}}
            className='button small outline w-inline-block'
            alignRight={alignRight}
            style={style}
            type={type}
        >   
            <div className='button-content-wrapper'>
                {label && <div className='button-text'>{label}</div>}
                {materialIconName && <div className='material-icons'>{materialIconName}</div>}
            </div>
        </ButtonWrap>
    )
}

export default AlignButton
