import React, { useEffect, useState} from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import RegisterFirstPage from './RegisterFirstPage'
import RegisterSecondPage from './RegisterSecondPage'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import alignHero from '../images/Align-G.png'
import colors from '../css/shared-styles'
import BreadcrumbBar from './BreadcrumbBar'
import { useNavigate } from 'react-router-dom'
import { useStore as useRootStore } from '../models/Root'
import { useUserManagerStore } from '../models/UserManager'
import useAxios from '../hooks/useAxios'
import { API_PATHS } from '../api/ApiPaths'
import {useLocation} from 'react-router-dom'
import Navbar from './Navbar'

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  div.overflow-handle {
    display: flex;
    flex: 1;
    overflow: auto;
  }
`

export const RegisterStyles = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    align-self: center;
    border-radius: 1rem;
    margin: 1rem;
    align-items: center; 
    margin-left: 4rem;
    margin-right: 4rem;
    
    .hero-image {
        display: flex; 
        height: 500px;
        max-width: 600px; 
        flex: 1;
        padding-left: 2rem;
        padding-right: 2rem;
        background: center / contain no-repeat url(${alignHero});
        border-radius: 20px;
        align-items: center;
        justify-content: center;
        background-size: 90%;
        margin-right: 3rem; 
    }

    .register-form {
        .form-wrap {
            display: flex;
            flex-direction: column;
            flex: 1;
            padding: 4.5rem;
            padding-top: 1rem; 
            padding-bottom: 1rem;
            height: 100%;
            max-width: 600px; 
            justify-content: center;
            background: #E6F6FC;
            border: 1px solid #B3C0C6;
            border-radius: 6px 6px 40px 6px;

            .input.medium {
                margin-bottom:0;
            }
            .input-with-label {
                margin-bottom: .5rem;
            }
            .requirements { 
                margin:0;
                font-size:12px;
            }
            .error {
                color: red;
            }
            .checkbox-text {
                margin-bottom: 0; 
                margin-left: 5px;
            }
            .user-credentials-error {
                display: flex;
                
                .alert-content{
                    div {
                        line-height: inherit;
                    }
                }
            }
            .button-holder {
                display: flex; 
                justify-content: flex-end; 
            }
            .button-holder-double {
                margin-top:20px;
                display: flex; 
                justify-content: space-between; 
            }
            .policy {
                background: none;
                color: ${colors.linkBlue} 
            }
            .strengthBar { 
                .strengthBar-text {
                    color: black !important;
                    text-align: left !important;
                    font-weight: bold;
                    margin-bottom: 5px !important;
                }
            }
        }
    }  
`
const formSteps: any[] = [{
    firstName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    lastName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
    title: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),            
    },{
    password: Yup.string()
        .min(8, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required')
        .matches(/[0-9]/, 'Password requires a number')
        .matches(/[a-z]/, 'Password requires a lowercase letter')
        .matches(/[A-Z]/, 'Password requires an uppercase letter')
        .matches(/[^\w]/, 'Password requires a symbol'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Both passwords must match')
        .required('Required'),            
    acceptedTerms: Yup.boolean()
        .required("Required")
        .oneOf([true], "You must accept the terms and conditions."),
    }
]

export interface IRegisterForm {
    firstName: string
    lastName: string
    email: string
    title: string
    password: string
    confirmPassword: string
    acceptedTerms: boolean
}

const Register:React.FC = observer(() => {
    const [ showNextPage, setShowNextPage] = useState(false); 
    const [ hasEmail, setHasEmail] = useState(false); 
    const navigate = useNavigate()
    const parsedEmail = useLocation().search.split('=')[1] || ''

    //ALL USED FOR FAKE LOGIN BC NO API 
    const {setAuthToken, setAppUser} = useRootStore()
    const {login: fakeLogin} = useUserManagerStore()
    const {data, payload, requestUrl, fetch} = useAxios({method:'POST',initialValue:null})
    //INITIATE FAKE LOGIN AFTER REGISTER
    const login = (email:string, password:string)=>{
        //we need to call the API here, get the token if successful and return errors if not.
        payload.current = {
          email: email,
          password: password
        }
        requestUrl.current = `${API_PATHS.LOGIN}`
        fetch()
    }
    // TAKEN FROM LOGIN COMPONENT
    useEffect(()=>{
        if(data) {
          if(data.issuccess) {
                // we will redirect and do extra stuff here.
                setAuthToken(data.entity.authToken)
                setAppUser(data.entity.appUser)
                //our fake users have roles, but API still does not, so we need to fake login them too for now
                fakeLogin('demouser@domain.com','!4PasswordShare')
                navigate("/dashboard",{replace:true})
            }
        }
    },[data, fakeLogin, navigate, setAuthToken, setAppUser])

    React.useEffect(() => {
        if (parsedEmail !== '') {
            setHasEmail(true)
        }
    }, [parsedEmail]);

    return (
        <AppWrapper>
            <Navbar
                style={{position:'relative', width:'100%', flexShrink:0}}
            />
            <div className='overflow-handle'>
                <RegisterStyles className='register-wrap'>
                    <div className='hero-image'></div>
                    <div className='register-form'>
                        <div className='form-wrap'>  
                            <BreadcrumbBar showNextPage={showNextPage}/>
                            <Formik
                                initialValues={{
                                firstName: "",
                                lastName: "",
                                email: parsedEmail,
                                title: "",
                                password: "",
                                confirmPassword: "",
                                acceptedTerms: false,
                                } as IRegisterForm}
                                validationSchema={ 
                                    !showNextPage 
                                    ? Yup.object().shape(formSteps[0]) 
                                    : Yup.object().shape(formSteps[1])
                                } 
                                onSubmit={async (values, { setSubmitting }) => {
                                    console.log("SUCCESSFUL", values)
                                    //call the api, second page has been validated
                                    //fakelogin with api for demo in meeting,
                                    //navigate to dashboard like succesful register  
                                    setAuthToken('')
                                    login('demouser@domain.com','!4PasswordShare')
                                    setSubmitting(false);
                                    alert("This is just for demo purposes, it does not create an account yet.")
                                }}
                            >
                                <div>
                                    <Form>
                                        {!showNextPage && <RegisterFirstPage setShowNextPage={setShowNextPage} hasEmail={hasEmail}/> }
                                        {showNextPage && <RegisterSecondPage setShowNextPage={setShowNextPage}/> }
                                    </Form>
                                </div>
                            </Formik>
                        </div>
                    </div>
                </RegisterStyles>
            </div>
        </AppWrapper>
    )
})

export default Register