import React from 'react'
import styled from 'styled-components'

export const BreadcrumbStyles = styled.div`
    display: flex;
    .progress-indicator {
        display: flex; 
        flex-direction: column; 
        align-items: center;
        margin-left: 50px;
        margin-right: 50px;  
        .progress-circle {
            border-radius: 50%;
            border: solid #99ABB3 2px; 
            height: 25px; 
            width: 25px; 
            justify-content: center;
            align-items: center; 
            display: flex;
            margin-bottom: 5px;
        } 

        .active {
            background-color: #0084B4;
            color: white; 
            border: none; 
        }
        .not-active {
            color: #99ABB3;
        }
        
        .label {
            font-family: Lato, sans-serif;
        }
    }
`

interface IBreadcrumbBar { 
    showNextPage: boolean 
}

const BreadcrumbBar:React.FC<IBreadcrumbBar> = (props) => {
    const { showNextPage } = props 
    return (
        <>
            <BreadcrumbStyles>
                <div className='progress-indicator'>
                    <div className='progress-circle active'>1</div>
                    <div className="label">Set up profile</div>
                </div>
                <div className='progress-indicator'>
                    <div className={`progress-circle ${showNextPage ? "active" : ""}`}>2</div>
                    <div className="label">Create Password</div>
                </div>
            </BreadcrumbStyles>
        </>
    )
}

export default BreadcrumbBar