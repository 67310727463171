import { createContext, useContext } from 'react'
import { types, Instance, getSnapshot, applySnapshot, SnapshotIn } from 'mobx-state-tree'
import localForage from 'localforage'
import { persist, IOptions } from 'mst-persist'

const STORAGE_KEY = 'root'

const AppUserModel = types.model('AppUser',{
    created_at: types.number,
    email: types.string,
    last_login: types.maybeNull(types.number),
    name: types.string,
    password_expires_at: types.maybeNull(types.number),
    title: types.optional(types.string,''),
    updated_at: types.maybeNull(types.number)
})

export type IAppUserInstance = Instance<typeof AppUserModel>
export interface IAppUser extends SnapshotIn<typeof AppUserModel> {}

const initAppUser = getSnapshot(AppUserModel.create({
    created_at: 0,
    email: 'example@example.com',
    last_login: null,
    name: 'initDefault'
}))


export const RootModel = types
    .model('Root',{
        authToken: types.optional(types.string,''),
        appUser:types.optional(AppUserModel, initAppUser)
    })
    .views(self => ({
        getAuthToken(){
            return self.authToken
        },
        getAppUser(){
            return self.appUser
        }
    }))
    .actions(self => ({
        setAuthToken(value:string){
            self.authToken = value
            //console.log('setting AuthToken', value)
        },
        resetState() {
            const defaultSnapshot = getSnapshot(RootModel.create())
            //console.log('resetting state', defaultSnapshot)
            applySnapshot(self, defaultSnapshot)
            return defaultSnapshot
        },
        setAppUser(appUser:IAppUser){
            applySnapshot(self.appUser, appUser)
        },
        resetAppUser(){
            applySnapshot(self.appUser, initAppUser)
        }
    }))

export const rootStore = RootModel.create()

export type RootInstance = Instance<typeof RootModel>

const RootStoreContext = createContext<RootInstance>({} as RootInstance)

export const Provider = RootStoreContext.Provider

export const useStore = () => {
    const store = useContext(RootStoreContext)
    if (store === null) {
        throw new Error('Store cannot be null, please add a context provider')
    }
    return store as RootInstance
}

export type MapStore<T> = (store: RootInstance) => T

export const useInject = <T>(mapStore: MapStore<T>) => {
    const store = useStore()
    return mapStore(store)
}

export const initPersistence = async (): Promise<RootInstance> => {
    // state persist, rehydrate
    const options: IOptions = {
        storage: localForage,
        jsonify: true
    }
    try {
        await persist(STORAGE_KEY, rootStore, options)
        console.log(' --> state rehydrated!')
    }
    catch (err) {
        // console.warn('ERROR rehydrating state', err, rootStore)
        var snapshot = rootStore.resetState()
        await localForage.setItem(STORAGE_KEY, JSON.stringify(snapshot))
    }
    return rootStore
}
