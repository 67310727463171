import React, {useEffect, useRef, useState} from 'react'
import App from './App'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import {Provider as MobXProvider, alignEditorStore } from './models/AlignEditor'
import DataGridDemo from './routes/DataGridDemo'
import AlignFlowDemo from './routes/AlignFlowDemo'
import ListManagerRoute from './routes/ListManagerRoute'
import ReportRoute from './routes/ReportRoute'
import AdminRoute from './routes/AdminRoute'
import {Provider as UserAuthProvider, UserManagerStore, ROLES } from './models/UserManager'
import RequireProtectedPath from './components/RequireProtectedPath'
import LoginRoute from './routes/LoginRoute'
import { Provider as RootProvider, rootStore, initPersistence } from './models/Root'
import DashboardRoute from './routes/DashboardRoute'
import RegisterRoute from './routes/RegisterRoute'

const Gate:React.FC = ()=>{
    const [isStateLoaded, setIsStateLoaded] = useState(false)
    const initOnce = useRef(true)
    useEffect(()=>{
        if(initOnce.current){
            initOnce.current = false
            initPersistence()
                .then(()=>{
                    setIsStateLoaded(true)
                })
        }
    },[])

    if(!isStateLoaded) {
        return null
    }
    
    return (
        <RootProvider value={rootStore}>
            <UserAuthProvider value={UserManagerStore}>
                <MobXProvider value={alignEditorStore}>
                    <BrowserRouter>
                        <Routes>
                            <Route path='/' element={<App />}>
                                <Route 
                                    element={<RequireProtectedPath 
                                    allowedRoles={[ROLES.ADMIN, ROLES.LEARNING_ARCHITECT]} 
                                />}>
                                <Route
                                    path='/datagriddemo'
                                    element={<DataGridDemo/>}
                                />
                                <Route 
                                    path='/flowdemo'
                                    element={<AlignFlowDemo />}
                                />
                                <Route 
                                    path='/listmanager'
                                    element={<ListManagerRoute />}
                                />
                                </Route>
                                <Route 
                                    element={
                                    <RequireProtectedPath 
                                        allowedRoles={[ROLES.ADMIN, ROLES.LEARNING_ARCHITECT, ROLES.TEST]} 
                                    />
                                }>
                                    <Route 
                                        path='/reports'
                                        element={<ReportRoute />}
                                    />
                                    <Route
                                        path='/dashboard'
                                        element={<DashboardRoute />}
                                    />
                                </Route>
                                <Route 
                                    element={
                                        <RequireProtectedPath 
                                            allowedRoles={[ROLES.ADMIN]} 
                                        />
                                    }
                                >
                                    <Route
                                        path='/admin'
                                        element={<AdminRoute />}
                                    />
                                </Route>
                            </Route>
                            <Route
                                path='/login'
                                element={<LoginRoute />}
                            />
                            <Route 
                                path='/register'
                                element={<RegisterRoute/>}
                            /> 
                            <Route
                                path="*"
                                element={<Navigate to="/login" replace />}
                            />
                        </Routes>
                    </BrowserRouter>
                </MobXProvider>
            </UserAuthProvider>
        </RootProvider>
    )
}

export default Gate
