import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import logo from '../images/IdesignLogo.svg'
import { AppBar, AppBarSection, AppBarSpacer } from '@progress/kendo-react-layout'
import { useUserManagerStore } from '../models/UserManager'
import { observer } from 'mobx-react-lite'
import RequireRole from './RequireRole'
import {ROLES} from '../models/UserManager'
import ProfileDropdown from './ProfileDropdown'
 
interface INavbar {
    style?:React.CSSProperties
}

/**
 * @description A component to display a navigation bar at the top of the application, it uses react-router-dom links for navigation
 * @style it accepts a styling object to customize its display
 */
const Navbar:React.FC<INavbar> = observer((props) => {
    const {style} = props
    const location = useLocation()
    const {isLoggedIn}=useUserManagerStore()
    const isCurrentPath = (path:string)=>{
        return (path === location.pathname? 'w--current':'')
    }
   
    return (
        <AppBar style={style}>
            <AppBarSection>
                <div className="brand-logo w-nav-brand">
                    <img src={logo} alt="IDesign" className="brand-logo-image" />
                </div>
            </AppBarSection>
            <AppBarSpacer style={{ width: '1rem' }} />
            <div className="nav-tabs w-nav">
                <div className="nav-tabs-container w-container">
                    {isLoggedIn && <AppBarSection className="nav-menu w-nav-menu">
                        <RequireRole allowedRoles={[ROLES.ADMIN]}>
                            <Link to="/admin" className={`nav-tab w-nav-link ${isCurrentPath('/admin')}`}>Admin</Link>
                        </RequireRole>
                        <RequireRole allowedRoles={[ROLES.ADMIN, ROLES.LEARNING_ARCHITECT]}>
                            <>
                            <Link to="/listmanager" className={`nav-tab w-nav-link ${isCurrentPath('/listmanager')}`}>Lists Manager</Link>
                            <Link to="/datagriddemo" className={`nav-tab w-nav-link ${isCurrentPath('/datagriddemo')}`}>Data Grid</Link>
                            <Link to="/flowdemo" className={`nav-tab w-nav-link ${isCurrentPath('/flowdemo')}`}>Flow Tree</Link>
                            </>
                        </RequireRole>
                        <RequireRole allowedRoles={[ROLES.ADMIN, ROLES.LEARNING_ARCHITECT, ROLES.TEST]}>
                            <Link to="/reports" className={`nav-tab w-nav-link ${isCurrentPath('/reports')}`}>Reports</Link>
                        </RequireRole>
                    </AppBarSection>}
                </div>
            </div>
            {isLoggedIn && <AppBarSection style={{flex:1,flexDirection:'column',alignItems:'flex-end'}}>
                <div className="nav-tabs-container w-container" style={{margin:'auto 0'}}>
                    <ProfileDropdown/>
                </div>
            </AppBarSection>}
        </AppBar>
    )
})

export default Navbar
