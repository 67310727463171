import React, { useEffect, useState } from 'react'
import {useStore } from '../models/AlignEditor'
import {IListManagerListItem as IDrawerListItem } from '../models/ListManager'
import '@progress/kendo-theme-default/dist/all.css'
import { 
        Grid, 
        GridColumn as Column,
        GridCellProps
    } from '@progress/kendo-react-grid'

import styled from 'styled-components'


const CustomGridStyles = styled.div`
    border-radius: 3px;
    margin-top: 1rem;
    div.k-grid-container {
        overflow: scroll;
    }
`

interface IAlignListManagerDataGrid {
    listId:number
}

const AlignDrawerListGrid: React.FC<IAlignListManagerDataGrid> = (props) => {
    const {listId}=props
    const {listDrawerInstance} = useStore()
    const [gridData, setGridData] = useState<IDrawerListItem[]>(listDrawerInstance().getListItems())
   

    useEffect(()=>{
        setGridData(listDrawerInstance().getListItems())
    },[listId, listDrawerInstance])

    
    return (
        <CustomGridStyles className='c-b-neutral-10' style={{overflow:'hidden'}}>
            <Grid
                style={{maxWidth:'100%', minWidth:'100%', overflow:'hidden', height:'100%'}}
                data={gridData}
            >
                <Column cell={(data:GridCellProps)=>{
                        let isHeading = data.dataItem.is_heading
                        let ifHeading:JSX.Element = isHeading
                            ?<strong>{data.dataItem.description}</strong>
                            :<span>{data.dataItem.description}</span>
                        return (
                            <td className='description-column' style={isHeading?{}:{paddingLeft:'1rem'}}>
                                {ifHeading}
                            </td>
                        )
                    }}
                    title="Description"
                />
            </Grid>
        </CustomGridStyles>
    )
}

export default AlignDrawerListGrid
