import React from 'react'
import styled from 'styled-components'

//This component renders a Button styled like a link.

interface IAlignLink {
    materialIconName?:string
    label?:string
    iconLeft?:boolean
    onClick?:(()=>void)
    style?:React.CSSProperties
}

const LinkWrap = styled.button<IAlignLink>`
    display: flex;
    width: fit-content;
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    div.material-icons {
        padding-left: 0;
        font-size: 1.25rem;
    }
`

const AlignLink: React.FC<IAlignLink> = props => {
    const {materialIconName, label, iconLeft, onClick, style} = props

    return (
        <LinkWrap 
            onClick={()=>{onClick && onClick()}}
            className='link-with-icon w-inline-block'
            style={style}
        >   
            <div className='text-icon-wrapper c-t-primary-60'>
                {materialIconName && iconLeft && <div className='material-icons'>{materialIconName}</div>}
                {label && <div className='button-text'>{label}</div>}
                {materialIconName && !iconLeft && <div className='material-icons'>{materialIconName}</div>}
            </div>
        </LinkWrap>
    )
}

export default AlignLink
